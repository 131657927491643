import * as React from 'react';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';
import { Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import desktop from './img/desktop.png'
import Coolimg from './img/coolimg.png'

const TabDIY = ({ ...props })=> {
  const context = useMyContext();

const DIYBackClick = ()=>{
  context.setAutoRotate(true);
  context.setPopIndex(5);
  gsap.to(context.cameraRef.current.position, { duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut'})
  gsap.to(context.OrbitRef.current.target, { duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'})
  gsap.to('.DIYTab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setDIYTabShow(false);}})
}

useGSAP(()=>{
  if(context.isDIYTabShow){
    gsap.to('.DIYTab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
    gsap.to('.DIYTab_card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
  }
},[context.isDIYTabShow])

  return (
    <Box 
    className='DIYTab'
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-end',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(-20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
        <Button 
          sx={{
            marginBottom:'3vh',
          }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick = {()=>{
              if(!context.isAutoRotate){
                  DIYBackClick();
              }
          }}
        >
          <Typography color="text.primary" variant="button">
            BACK
          </Typography>
        </Button>

        <Box
        className='DIYTab_card'
            sx={{
              width:{
                xs:'100%',
                sm:'40vw',
              },
              boxSizing:'border-box',
              display:'flex',
              flexDirection:'column',
              alignItems: 'center',
              background: 'rgba(251, 251, 253, 0.1)',
              boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
              webkitBackdropFilter: ' blur(10px)',
              backdropFilter: 'blur(10px)',
              padding:{
                xs:'1rem',
                sm:'2rem',
              },
              borderRadius:{
                xs:'10px',
                sm:'20px',
              },
            }}
        >
          <Typography color="text.primary"  gutterBottom variant="h4">
            Multiple DIY Attachment Options
          </Typography>
          
          <Typography color="text.primary"  variant="body2">
            You can utilize the installation threads to design your own attachment options for the CoCoon. These attachment options give you creative freedom to attach the CoCoon directly onto your 3D printer or a dedicated filament shelf.  
          </Typography>

          <Stack
            justifyContent='space-between'
            direction='row'
            marginTop='2em'
          >

            <Avatar
             src={desktop}
             sx={{
              width: '49%',
              height:  'auto', 
              borderRadius: '0.6em',
            }}
            >
            </Avatar>

            <Avatar
             src={Coolimg}
             sx={{
              width: '49%',
              height:  'auto', 
              borderRadius: '0.6em',
            }}
            >
            </Avatar>

          </Stack>
        </Box>

      </Box>
      
    </Box>
  );
}

export default TabDIY