import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import musicUrl from './img/m1.mp3';
import { useMyContext } from './stateContext';

function MusicSwitch() {
  const [musicOn, setMusicOn] = useState(false);
  const [audio, setAudio] = useState(null);
  const context = useMyContext();


  useEffect(() => {

    if(context.isLoaded){
        const newAudio = new Audio(musicUrl);
        newAudio.loop = true;
        newAudio.play();
        setAudio(newAudio); // 保存新的音频实例
      setMusicOn(true);
    }
  }, [context.isLoaded]);

  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause(); // 清理时暂停音乐
      }
    };
  }, [audio]);

  const handleToggle = () => {
    setMusicOn((prev) => !prev);
    musicOn ? audio.pause() : audio.play();
  };

  return (
    <div>
      <Switch
        defaultChecked={true}
        onChange={handleToggle}
        inputProps={{ 'aria-label': 'toggle music' }}
      />
      <IconButton color="primary" aria-label="music icon">
        {musicOn ? <MusicNoteIcon /> : <MusicOffIcon />}
      </IconButton>
    </div>
  );
}

export default MusicSwitch;