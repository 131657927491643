import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material'
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import ShoppingCartIcon from '@mui/icons-material/LocalMallOutlined'
import Box from '@mui/material/Box'

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
        display='flex'
    >
      <Button
        id="fade-button"
        variant='contained'
        startIcon={
            <ShoppingCartIcon
              sx={{
                color:'primary.main',
                fontSize: {
                  xs: '16px !important', 
                  sm: '20px !important',
                }
              }}
            />}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Typography color="text.primary" variant="button">
            Buy
        </Typography>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
            <Link href='https://www.fabreeko.com/' target='_blank' rel="noopener">
            <Typography color="text.primary" variant="button">Fabreeko USA</Typography>
            </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
            <Link href='https://west3d.com/' target='_blank' rel="noopener">
            <Typography color="text.primary" variant="button">west 3D USA</Typography>
            </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
            <Link href='https://meltbro.de/' target='_blank' rel="noopener">
            <Typography color="text.primary" variant="button">Meltbro Germany</Typography>
            </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
            <Link href='https://dfh.fm/' target='_blank' rel="noopener">
            <Typography color="text.primary" variant="button">DFH USA</Typography>
            </Link>
        </MenuItem>

          <MenuItem onClick={handleClose}>
              <Link href='https://www.digmach.com/collections/phaetus' target='_blank' rel="noopener">
                  <Typography color="text.primary" variant="button">USA Digital Machinery Inc.</Typography>
              </Link>
          </MenuItem>
      </Menu>
    </Box>
  );
}