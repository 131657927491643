import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box'
import { Html } from '@react-three/drei'
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";
import { duration } from '@mui/material';
import { useMyContext } from '../../stateContext';



const SizeUI_Line = {
  width: '778px',
  position: 'absolute',
  borderBottom: '1px solid #ffffff',
};

const SizeUI2_Line = {
  width: '730px',
  position: 'absolute',
  borderBottom: '1px solid #ffffff',
};

const SizeUI3_Line = {
  width: '300px',
  position: 'absolute',
  borderBottom: '1px solid #ffffff',
};

const SizeUI4_Line = {
  width: '540px',
  position: 'absolute',
  border: '1px dashed #ffffff',
  transform: 'rotate(45deg)',
};

const SizeUI4_Line2 = {
  width: '540px',
  position: 'absolute',
  border: '1px dashed #ffffff',
  transform: 'rotate(-45deg)',
};

const SizeUI_p = {
  padding: '10px',
  backgroundColor: 'rgba(255, 255, 255)',
  color: 'rgba(255, 92, 0, 0.7)',
  zIndex:'1',
  borderRadius: '100px',
  fontWeight: '600',
};

const SizeUI = {
  width: '778px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  height: '20px',
  borderLeft: '1px solid #ffffff',
  borderRight: '1px solid #ffffff',
  opacity:1,
};
const SizeUI2 = {
  width: '730px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  height: '20px',
  borderLeft: '1px solid #ffffff',
  borderRight: '1px solid #ffffff',
  opacity:1,
};

const SizeUI3 = {
  width: '300px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  height: '20px',
  borderLeft: '1px solid #ffffff',
  borderRight: '1px solid #ffffff',
  opacity:1,
};

const SizeUI4 ={
  width: '540px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  height: '540px',
  borderRadius: '300px',
  border: '1px solid #ffffff',
  opacity:1,
}

const Sizeui = () => {

  const context = useMyContext();


  return (
    <>
      <Html ref={context.SizeUI1Ref} zIndexRange={[0, 200]} rotation={[Math.PI / -2, 0, Math.PI / 2]} distanceFactor={3}  position={[context.SizeX1, context.SizeY1, context.SizeZ1]} transform >
        <Box style={SizeUI}>
          <Box style={SizeUI_Line}></Box>
          <p style={SizeUI_p}>300mm</p>
        </Box>
      </Html>

      <Html ref={context.SizeUI2Ref} zIndexRange={[0, 200]} rotation={[0, Math.PI / 2, Math.PI / 2]} distanceFactor={3}  position={[context.SizeX2, context.SizeY2, context.SizeZ2]} transform >
        <Box style={SizeUI2}>
          <Box style={SizeUI2_Line}></Box>
          <p style={SizeUI_p}>277mm</p>
        </Box>
      </Html>

      <Html ref={context.SizeUI3Ref} zIndexRange={[0, 200]} rotation={[Math.PI /-2, 0, Math.PI /1]} distanceFactor={3}  position={[context.SizeX3, context.SizeY3, context.SizeZ3]} transform >
        <Box style={SizeUI3}>
          <Box style={SizeUI3_Line}></Box>
          <p style={SizeUI_p}>120mm</p>
        </Box>
      </Html>
    
      <Html ref={context.SizeUI4Ref} zIndexRange={[0, 200]} rotation={[0, Math.PI / 2, 0]} distanceFactor={3}  position={[context.SizeX4, context.SizeY4, context.SizeZ4]} transform >
        <Box style={SizeUI4}>
          <Box style={SizeUI4_Line}></Box>
          <Box style={SizeUI4_Line2}></Box>
          <p style={SizeUI_p}>Φ206mm</p>
        </Box>
      </Html>
    </>
  );
};

export default Sizeui;
