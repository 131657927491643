import { useState,useEffect } from 'react'
import {Html} from '@react-three/drei'
import theme from '../../theme'
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles'


const HtmlComponent = ({ occludeDefault, style, position, text, onClick,onMouseEnter,onMouseLeave}) => {
    const [isOccluded, setOccluded] = useState(occludeDefault);

    function textStyle(isOccluded){
        return {
            transition: 'all 0.5s',
            opacity: isOccluded ? 0 : 1
        }
    }

    useEffect(() => {

    }, []);

    return (
      <Html occlude="i" onOcclude={e=>{
          setOccluded(e);}} style={textStyle(isOccluded)} zIndexRange={[0, 200]} rotation={[0, Math.PI / 2, 0]} distanceFactor={1} position={position}>
        {/* 使用传递进来的text属性渲染文字内容 */}
        <ThemeProvider theme={theme}>
        <Button
         sx={{
           transform:'translate(-50%, -50%)!important',
           background:'rgba(0, 0, 0, 0.3)!important',
           '&:hover': {
            background:'rgba(0, 0, 0, 0.5) !important',
           },
         }}
         variant='contained'
         size="large"
        onClick={onClick} 
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ><Typography color="text.secondary" variant="button2"> {text}</Typography></Button>
        </ThemeProvider>
      </Html>

    )

  }

  export default HtmlComponent