import './styles.css'

import {useRef, useEffect,forwardRef, useState, useMemo, useReducer,Suspense} from 'react'
import { useGLTF,Merged,Grid ,CameraControls, AdaptiveDpr} from '@react-three/drei'
import { useSpring, animated } from '@react-spring/web'
import Tab from './Tab'
import TabUI from './TabUI'
import TabDIY from './TabDIY.js'
import TabTYPEC from './TabTYPEC.js'
import TabBATTERY from './TabBATTERY.js'
import TabAOTLET from './TabAOTLET.js'
import Cooldesktop from './CoolDesktop.js'


import theme from './theme'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment,PerspectiveCamera, Html, OrbitControls, Stage, ContactShadows } from '@react-three/drei'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Perf } from 'r3f-perf'
import gsap from 'gsap'
import { ThemeProvider } from '@mui/material/styles'
import ModelComponent from './components/Model/ModelComponent'
import NavigationComponent from './components/Nav/NavigationComponent'
import Loader from './components/Loader/Loader'
import Loaded from './components/Loader/Loaded'
import { Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { MyContextProvider,useMyContext } from './stateContext';import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import Hidden from '@mui/material/Hidden'
import Marquee from "./components/Marquee";
import { useGSAP } from '@gsap/react'


const App = () => {

  const context = useMyContext();

    const sunset = 'venice_sunset_1k.hdr';//环境HDR

    // 加载

    // const gridConfig = {
    //   cellSize: 0.1,
    //   cellThickness: 1,
    //   cellColor: '#9CA5B4',
    //   sectionSize: 1,
    //   sectionThickness: 1,
    //   sectionColor: '#BCC4D4',
    //   fadeDistance: 5,
    //   fadeStrength: 1,
    //   followCamera: false,
    //   infiniteGrid: true
    // }

    const fov = context.fovsm ? 20 : context.fovmd ? 18 : 15;

    const [isNoticeopen, setNoticeopen] = useState(true);//公告栏状态

    useGSAP(()=>{
      gsap.to(context.GridRef.current,{duration:1,fadeDistance:context.isSpace ? 5 : 12,ease: 'power2.inOut'})
    },[context])

    useGSAP(()=>{
      if(context.isLoaded){
        gsap.to(context.cameraRef.current.position,{duration:1,x:context.fovsm ? 5 : 4,y:1,z:context.fovsm ? 5 : 4,onComplete:()=>{
            context.setAutoRotate(true);
            }})
      }
    },[context.isLoaded])



    //当点击湿度选项卡的时候，背景变红
    let backgroundHumidityColor = context.isHumidity ? '#FF0000':'#646C79';


  return (

          <ThemeProvider theme={theme}>
              <Suspense fallback={<Loader />}>

      <Box
          className={'bodyBackgroundColor'}
        sx={{
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          touchAction: 'none',
          background: `linear-gradient(180deg, #1B2026 0%, ${backgroundHumidityColor} 100%)`,
        }}
      >

              {!context.isLoaded && <Loaded showBtn={context.isLoader} />}
              <>
                    <Hidden smDown>
                      <Collapse in={isNoticeopen}>
                        <Alert
                            sx={{
                              color:'text.primary',
                              padding:'0 1rem',
                              backgroundColor:'background.default',
                              textAlign: 'center',
                              alignItems: 'center',
                              borderRadius:'0',
                              boxShadow: 'inset 0px -2px 5px rgba(0, 0, 0, 0.5)',
                              '& .MuiAlert-message': {
                                width: '100%', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              '& .MuiAlert-action':{
                                padding:'0',
                                },
                            }}
                        icon={
                          <LocalOfferOutlinedIcon
                          fontSize='small'
                          sx={{
                            color:'text.secondary',
                          }}
                          />}
                        action={
                          <IconButton
                          aria-label="close"
                          size="small"
                          onClick={() => {
                            setNoticeopen(false);
                          }}
                        >
                          <CloseIcon
                            fontSize="inherit"
                            sx={{
                              color:'text.secondary',
                            }} />
                          </IconButton>
                        }
                        >
                          <Typography variant="caption">
                          Suggested retail price: <b>$49</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Available for purchase: <b>June</b>
                          </Typography>
                        </Alert>
                      </Collapse>
                    </Hidden>
                    <NavigationComponent />
                    {!(context.isBtnOpen || context.isShowUI || context.isUSBTabShow || context.isPowerTabShow || context.isLetTabShow || context.isDIYTabShow) && <Box sx={{
                      position:'absolute',
                      opacity:1,
                      overflow:'hidden',
                      width:'100vw',
                      height:'15vh',
                      top: '15vh'
                      }}>
                        <Marquee />
                    </Box>}

                    {context.isBtnOpen && <Tab/>}

                    {context.isUITabShow && <TabUI />}

                    {context.isDIYTabShow && <TabDIY />}

                    {context.isUSBTabShow && <TabTYPEC />}

                    {context.isPowerTabShow && <TabBATTERY />}

                    {context.isLetTabShow && <TabAOTLET />}
                    {context.isCooldesktopShow && <Cooldesktop />}
                    
                    
                    <Canvas>
                        <AdaptiveDpr pixelated /> 
                        <Grid ref={context.GridRef} position={[0, -0.59, 0]} args={[1, 1]} {...context.gridConfig} />
                        <PerspectiveCamera ref={context.cameraRef} makeDefault position={[10, 0, 0]} fov={fov} zoom={1} />
                        {/* 性能监视器 */}
                        {/* <Perf deepAnalyze="true" matrixUpdate="true" chart="length"/> */}

                        {/* 添加轨道控制，禁用缩放和平移功能 */}
                        <OrbitControls
                          ref={context.OrbitRef}
                          enableZoom={false}
                          enablePan={false}
                          enableRotate={context.isBtnOpen?false:true}
                          autoRotate={!context.isBtnOpen && context.isAutoRotate}
                          autoRotateSpeed={-2}
                          enableDamping = {(context.isBtnOpen || context.isShowUI || context.isUSBTabShow || context.isPowerTabShow || context.isLetTabShow || context.isDIYTabShow) ? false : true}
                        />
          
                        {/* 在场景中添加坐标轴辅助线，大小为100 */}
                         {/* <axesHelper size={100} /> */}

                        {/* 在Canvas中添加环境，预设为"sunset" */}
                        <Environment exposure={0} files={sunset}   />
                
                        {/* 投影 */}
                        <ContactShadows position={[0, -0.58, 0]} opacity={0.7} scale={10} blur={1} far={0.8} />
                   
                          <ModelComponent
                          // setBtnOpenValue={context.setBtnOpenValue}
                          />
                    
                        
                    </Canvas>
                    
              </>

          </Box>
              </Suspense>

      </ThemeProvider>


  )
}


export default App