import { createTheme  } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#1A1F27',
    },
    background: {
      default: '#1A1F27',
      paper: '#1A1F27',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#9CA5B4',
    },
  },

  typography:{
    fontFamily: [
      'sans-serif',
      'Helvetica Neue',
      'Helvetica',
      'Roboto',
      'SF Pro Icons',
      'SF Pro Display',
      'Arial',
    ],

    h1: {
      fontSize: '10em',
      fontWeight:'800',
    },
    h3: {
      fontSize: '10em',
    },
    h4: {
      fontSize: '2em',
    },
    button:{
      fontSize: '1em',
      whiteSpace: 'nowrap',
    },
    button2:{
      fontSize: '1.4em',
      fontWeight:'600',
      whiteSpace: 'nowrap',
    },
    body2:{
      fontSize: '1em',
      textAlign: 'justify',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          webkitBorderRadius: '6px',
          webkitBackdropFilter: 'blur(20px)',
          backdropFilter: 'blur(20px)',
          background: 'rgba(251, 251, 253, 0.1)',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1), inset 0px 0.5px 1px rgba(225, 225, 225, 0.5)',

          '&.MuiButton-contained': {
            '&:hover': {
              background: 'rgba(251, 251, 253, 0.2)',
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2), inset 0px 0.5px 1px rgba(225, 225, 225, 0.5)',
            },
          },

          '&.MuiButton-outlined': {
            background: 'transparent',
            boxShadow: 'none',
            
            '&:hover': {
              background: 'rgba(251, 251, 253, 0.1)',
              boxShadow: 'none',
            },
          },

          '&.MuiButton-text': {
            background: 'transparent',
            boxShadow: 'none',
            backdropFilter:'none',
            
            '&:hover': {
              background: 'transparent',
              boxShadow: 'none',
            },
          },

        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color:'rgba(251, 251, 253, 0.5)',
          borderRadius: '5px',
          '&:hover': {
            color:'rgba(251, 251, 253, 1)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(251, 251, 253, 0.1)',
            boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), inset 0px 0.5px 1px rgba(225, 225, 225, 0.5)',
          },
        },
      },
    },

    MuiTabPanel:{
      styleOverrides: {
        root: {
          padding:'0',
        },
      },
    },

  },


  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },

});

theme.typography.h1[theme.breakpoints.down('sm')] = {
  fontSize: '4em',
};

theme.typography.h1[theme.breakpoints.up('xl')] = {
  fontSize: '20em',
};

theme.typography.h4[theme.breakpoints.down('sm')] = {
  fontSize: '1.2em',
};

theme.typography.button[theme.breakpoints.down('sm')] = {
  fontSize: '0.8em',
};

theme.typography.button2[theme.breakpoints.down('sm')] = {
  fontSize: '2em',
};

theme.typography.body2[theme.breakpoints.down('sm')] = {
  fontSize: '0.1em',
};

export default theme;


