import * as React from 'react';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';


const TabBATTERY = ({ ...props })=> {
  const context = useMyContext();

  const PowerBackClick = ()=>{

      context.setPopIndex(6);
      context.setAutoRotate(true);
      gsap.to(context.OrbitRef.current.target, { duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'})
      gsap.to(context.cameraRef.current.position, {  onComplete:()=>{context.setAutoRotate(true);context.setBatteryShow(false)},duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut'})
      gsap.to('.PowerTab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setPowerTabShow(false)}})
    }

  useGSAP(()=>{
    if(context.isPowerTabShow){
      gsap.to('.PowerTab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
      gsap.to('.PowerTab_card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
    }
  },[context.isPowerTabShow])

  return (
    <Box 
    className='PowerTab'
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-start',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
        <Button 
          sx={{
            marginBottom:'3vh',
          }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick = {()=>{
              if(!context.isAutoRotate){
                  PowerBackClick();
              }
          }}
        >
          <Typography color="text.primary" variant="button">
            BACK
          </Typography>
        </Button>

        <Box
        className='PowerTab_card'
            sx={{
              width:{
                xs:'100%',
                sm:'40vw',
              },
              boxSizing:'border-box',
              display:'flex',
              flexDirection:'column',
              alignItems: 'center',
              background: 'rgba(251, 251, 253, 0.1)',
              boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
              webkitBackdropFilter: ' blur(10px)',
              backdropFilter: 'blur(10px)',
              padding:{
                xs:'1rem',
                sm:'2rem',
              },
              borderRadius:{
                xs:'10px',
                sm:'20px',
              },
            }}
        >
          <Typography color="text.primary"  gutterBottom variant="h4">
            Low Power Consumption and Safer
          </Typography>
          
          <Typography color="text.primary"  variant="body2">
            With a power consumption of less than 1W, it can be operated for several months with three AAA batteries, making the CoCoon very portable and safe to use.
          </Typography>

        </Box>

      </Box>
      
    </Box>
  );
}

export default TabBATTERY