import React from 'react'
import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import KV from './img/kv.png'
import AE from './img/ae.png'
import Coolimg from './img/coolimg.png'
import CoolTitle from './img/CoolTitle.svg'
import { Box } from '@mui/system'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import {useMyContext } from './stateContext';
import {useEffect,useRef} from "react";

import isscroll from "./isscroll";

const Cooldesktop = () => {

    const context = useMyContext();

    let flag = true;

    const desktopRef = useRef();

    useGSAP(()=>{
        if(context.isCooldesktopShow){
            gsap.to('.CooldesktopPage',{
                duration:1,
                opacity:1,
                transform:'translate(0%,0%)',
                ease: 'power2.inOut'
            })
            gsap.to('.CooldesktopPageBack',{
                duration:1,
                opacity:1,
                ease: 'power2.inOut'
            })
        }
    },[context.isCooldesktopShow])

    const CooldesktopBack =()=>{
        flag = false;
        gsap.to('.CooldesktopPage',{
            duration:1,
            opacity:0,
            transform:'translate(0%,50%)',
            ease: 'power2.inOut',
            onComplete:function(){
                context.setCooldesktopShow(false) 
                context.setAutoRotate(true);
            }
        })
        gsap.to('.CooldesktopPageBack',{
            duration:1,
            opacity:0,
            ease: 'power2.inOut'
        })
    }

    useGSAP(()=>{


        gsap.to(".CoolTitle",{
            duration:context.fovmd? 50 : 30,
            ease:'none',
            x:context.fovmd ?"-=100vw" : "-=200vw",
            modifiers: {
                
                x: gsap.utils.unitize(x => {
                    const divisor = context.fovmd ? 100 : 200;
                    return parseFloat(x) % divisor ;
                }) //force x value to be between 0 and 500 using modulus
              },
              repeat: -1
        })
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          

    },[context.isLoaded, context.fovmd])

    useEffect(()=>{

        isscroll(desktopRef.current,false);

    },[]);


  return (
    <>
    <Button variant="contained"
    className='CooldesktopPageBack'
    onClick={()=>{
        if(flag){
            CooldesktopBack();
        }
    }}
      sx={{
        position:'fixed',
        width:'5em',
        height:'5em',
        borderRadius: '100px',
        zIndex:'1000',
        backgroundColor:'#ffffff',
        bottom:'1em',
        right:'1em',
        opacity:0,
      }}
    >
        Back
    </Button>
    <Box
    className='CooldesktopPage'
    ref={desktopRef}
        sx={{
            width:'100vw',
            overflowX:'hidden',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex:'999',
            opacity:0,
            transform:'translate(0%,50%)'
        }}
    >
        <Box
            sx={{
                height:'100vh',
            }}
        >
            <Avatar
                alt="Logo"
                src={KV}
                sx={{
                width:'100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                zIndex:'-1',
                borderRadius: '0',
                boxSizing:'border-box',
                }}
            />

            <Box className='CoolTitleContainer'
            sx={{
                display:'flex',
                width:{
                  xs:'400vw',
                  sm:'200vw',
                }
              }}
            >
                <Avatar
                    className='CoolTitle'
                    alt="Logo"
                    src={CoolTitle}
                    sx={{
                    padding:'2% 0%',
                    width: {
                        xs: '200vw',
                        sm: '100vw',
                    },
                    height:'auto',
                    borderRadius: '0',
                    }}
                />
                <Avatar
                    className='CoolTitle'
                    alt="Logo"
                    src={CoolTitle}
                    sx={{
                    padding:'2% 0%',
                    width: {
                        xs: '200vw',
                        sm: '100vw',
                    },
                    height:'auto',
                    borderRadius: '0',
                    }}
                />
            </Box>

            <Box
                sx={{
                boxSizing:'border-box',
                padding:'2%',
                width: {
                    xs:'100%',
                    sm:'40vw',
                },
                top:'8em',
            }}
            >
                <Typography
                color="text.primary"
                variant="body2"
                > 
                    CoCoon not only protects your consumables, but also comes with high aesthetics. It also reserves hanging hole positions for DIY placements with the machine, meeting your different workstation needs in various scenarios!<br/>
                    <b>Join Phaetus' #CoolDesktop topic event and tag <Link href="https://twitter.com/phaetus3D" target="_blank">@Phaetus3D</Link>;</b><br/>
                    Share your workstation beauty photos with CoCoon!
                </Typography>

                <Box
                    sx={{
                        padding:'2%',
                        width:'10em',
                        marginTop:'2em',
                        borderRadius: '6px',
                        webkitBorderRadius: '6px',
                        webkitBackdropFilter: 'blur(20px)',
                        backdropFilter: 'blur(20px)',
                        background: 'rgba(251, 251, 253, 0.1)',
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            alt="Logo"
                            src={AE}
                            sx={{
                                width:'1em',
                                height:'1em',
                            }}
                        />
                        <Typography
                            color="text.primary"
                            variant="body2"
                            marginLeft="6px"
                        > 
                            <Link href="https://twitter.com/phaetus3D" target="_blank">Phaetus3D</Link>
                        </Typography>
                    </Stack>
                    <Typography
                            color="text.primary"
                            marginTop="6px"
                            fontSize="12px"
                        > 
                            #CoolDesktop<br/>
                            I'm waiting for you here!😊
                    </Typography>

                    <Avatar
                            alt="Logo"
                            src={Coolimg}
                            sx={{
                                width:'100%',
                                height:'auto',
                                borderRadius: '6px',
                                marginTop:'6px',
                            }}
                    />
                </Box>

            </Box>
        </Box>

        <Box
            sx={{
                padding:'2%',
                backgroundColor:'background.default',
                height: {
                    xs: 'auto', 
                },
            }}
        >
            <Typography
                color="text.primary"
                variant="h4"
                textAlign="center"
                marginTop="2em"
            > 
                Fan works
            </Typography>

            <Box
              sx={{
                display:'flex',
                flexDirection:'row',
                flexWrap:'wrap',
                justifyContent: 'space-between',
              }}
            >
                <Box
                  sx={{
                    width:{
                        xs:'100%',
                        sm:'30%',
                    },
                    height:'auto',
                    marginTop:'2em',
                  }}
                >
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            alt="Logo"
                            src={AE}
                            sx={{
                                width:'1em',
                                height:'1em',
                            }}
                        />
                        <Typography
                            color="text.primary"
                            variant="body2"
                            marginLeft="6px"
                        > 
                            <Link href="https://twitter.com/phaetus3D" target="_blank">Phaetus3D</Link>
                        </Typography>
                    </Stack>

                    <Avatar
                            alt="Logo"
                            src={Coolimg}
                            sx={{
                                width:'100%',
                                height:'auto',
                                borderRadius: '6px',
                                marginTop:'1em',
                            }}
                    />
                </Box>

                <Box
                  sx={{
                    width:{
                        xs:'100%',
                        sm:'30%',
                    },
                    height:'auto',
                    marginTop:'2em',
                  }}
                >
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            alt="Logo"
                            src={AE}
                            sx={{
                                width:'1em',
                                height:'1em',
                            }}
                        />
                        <Typography
                            color="text.primary"
                            variant="body2"
                            marginLeft="6px"
                        > 
                            <Link href="https://twitter.com/phaetus3D" target="_blank">Phaetus3D</Link>
                        </Typography>
                    </Stack>

                    <Avatar
                            alt="Logo"
                            src={Coolimg}
                            sx={{
                                width:'100%',
                                height:'auto',
                                borderRadius: '6px',
                                marginTop:'1em',
                            }}
                    />
                </Box>

                <Box
                  sx={{
                    width:{
                        xs:'100%',
                        sm:'30%',
                    },
                    height:'auto',
                    marginTop:'2em',
                  }}
                >
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            alt="Logo"
                            src={AE}
                            sx={{
                                width:'1em',
                                height:'1em',
                            }}
                        />
                        <Typography
                            color="text.primary"
                            variant="body2"
                            marginLeft="6px"
                        > 
                            <Link href="https://twitter.com/phaetus3D" target="_blank">Phaetus3D</Link>
                        </Typography>
                    </Stack>

                    <Avatar
                            alt="Logo"
                            src={Coolimg}
                            sx={{
                                width:'100%',
                                height:'auto',
                                borderRadius: '6px',
                                marginTop:'1em',
                            }}
                    />
                </Box>

                <Box
                  sx={{
                    width:{
                        xs:'100%',
                        sm:'30%',
                    },
                    height:'auto',
                    marginTop:'2em',
                  }}
                >
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            alt="Logo"
                            src={AE}
                            sx={{
                                width:'1em',
                                height:'1em',
                            }}
                        />
                        <Typography
                            color="text.primary"
                            variant="body2"
                            marginLeft="6px"
                        > 
                            <Link href="https://twitter.com/phaetus3D" target="_blank">Phaetus3D</Link>
                        </Typography>
                    </Stack>

                    <Avatar
                            alt="Logo"
                            src={Coolimg}
                            sx={{
                                width:'100%',
                                height:'auto',
                                borderRadius: '6px',
                                marginTop:'1em',
                            }}
                    />
                </Box>
                
            </Box>

        </Box>
    </Box>
    </>
  );
};

export default Cooldesktop;
