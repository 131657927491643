import React, { useMemo } from "react";
import { useGLTF, Outlines,Edges } from "@react-three/drei";
import { useMyContext } from '../../stateContext';
import { useSpring, animated } from '@react-spring/three'
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";
function Desiccant(props) {
  const { nodes, materials } = useGLTF("./Desiccant.glb");


  const context = useMyContext();


  useGSAP(()=>{

    gsap.to([context.Desiccant1_Ref.current.material,context.Desiccant2_Ref.current.material,context.Desiccant3_Ref.current.material,context.Desiccant4_Ref.current.material,],
      {duration:1,opacity:context.isSpace ? 1 : 0.1,ease: 'power2.inOut', delay:context.isSpace?1.3:0.1,})
      gsap.to([context.Desiccant1_Ref.current.position,context.Desiccant4_Ref.current.position,],
        {duration:1,y:context.isSpace ? -2.309 : -2,ease: 'power2.inOut', delay:context.isSpace?1.4:0.1,})
      gsap.to([context.Desiccant2_Ref.current.position,context.Desiccant3_Ref.current.position,],
        {duration:1,y:context.isSpace ? -2.302 : -2,ease: 'power2.inOut', delay:context.isSpace?1.4:0.1,})
  },[context])
  // materials.Desiccant.depthTest=false
  materials.Desiccant.transparent=true;
  materials.Desiccant.opacity=0.1;


  return useMemo(() => (
    <group {...props} dispose={null}>
      <mesh
        ref={context.Desiccant1_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Desiccant002.geometry}
        material={materials.Desiccant}
        position={[0, -2, -2.048]}
        rotation={[0.589, 0, 0]}
      >
      </mesh>
      <mesh
        ref={context.Desiccant2_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Desiccant.geometry}
        material={materials.Desiccant}
        position={[0, -2, 2.082]}
        rotation={[-0.619, 0, 0]}
      >
      </mesh>
      <mesh
        ref={context.Desiccant3_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Desiccant001.geometry}
        material={materials.Desiccant}
        position={[0, -2, 1.644]}
        rotation={[-0.619, 0, 0]}
      >
      </mesh>
      <mesh
        ref={context.Desiccant4_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Desiccant003.geometry}
        material={materials.Desiccant}
        position={[0, -2, -1.61]}
        rotation={[0.589, 0, 0]}
      >
      </mesh>
    </group>
  ), [props]); // You can add other dependencies if needed
}

useGLTF.preload("/Desiccant.glb");

export default Desiccant;
