import { useGLTF } from "@react-three/drei";
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";
import { useMyContext } from '../../stateContext';



 function USBLine(props) {
  const { nodes, materials } = useGLTF("/USB.glb");
  const context = useMyContext();

  materials.Plastic_White.opacity=0;
  materials.Plastic_White.transparent = true;
  materials.Metal.opacity=0;
  materials.Metal.transparent = true;
  useGSAP(()=>{
    
    const USBLinetl =gsap.timeline();
    if(context.isUSBTabShow){
        setTimeout(function(){
        USBLinetl
        .to(context.USBLineRef.current.children[1].material,{duration:1,opacity:1}) 
        .to(context.USBLineRef.current.children[0].material,{duration:1,opacity:1},"<")
        .to(context.USBLineRef.current.position,{duration:1,z:-3.35})
        },1000)
    }

  },[])


  return (
    <group {...props} dispose={null}>
      <group ref={context.USBLineRef} position={[-0.07, -0.662, -3.625]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.data_cable_1.geometry}
          material={materials.Plastic_White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.data_cable_2.geometry}
          material={materials.Metal}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/USB.glb");
export default USBLine