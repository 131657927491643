import { createRoot } from 'react-dom/client';
import App from './App';
import { StrictMode ,Suspense} from 'react';
import { MyContextProvider} from './stateContext';
import Loader from './components/Loader/Loader'

const root = document.getElementById('root');

createRoot(root).render(
  <StrictMode>
    <MyContextProvider>
    <App />
    </MyContextProvider>

  </StrictMode>
);