import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/LocalMallOutlined'
import Button from '@mui/material/Button'
import LogoSvg from './logo.svg'
import { Stack } from '@mui/material'
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import MusicSwitch from '../../MusicSwitch'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance'
import Badge from '@mui/material/Badge'
import {useMyContext } from '../../stateContext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FadeMenu from '../Nav/Buy.js'


const Navigation = () => {
  const context = useMyContext();
const CameraIconClick = ()=>{
  context.setCooldesktopShow(true);
  context.setAutoRotate(false);
}

  return (
    <Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        width='100vw'
        height='5rem'
        padding='1rem'
        boxSizing='border-box'
        position='absolute'
        zIndex='999'
      >
        <Stack
          spacing={2}
          alignItems='center'
          direction='row'
        >

          <Avatar
            alt="Logo"
            src={LogoSvg}
            sx={{
              width: {
                xs: '35%',
                sm: '44%',
              },
              height: {
                xs: 'auto', 
                sm: 'auto',
              },
              borderRadius: '0',
            }}
          />

          <Typography color="text.primary" variant="body2"> 
          <b>aeBox CoCoon</b><br />
          Filament Dry Box</Typography>
        </Stack>
        <FadeMenu />
      </Stack>

      <Stack
        bottom='0'
        padding='1rem'
        width='100vw'
        zIndex='999'
        position='fixed'
        justifyContent='space-between'
        alignItems='center'
        direction='row'
        boxSizing='border-box'
      >
        <Badge className='CameraIcon' onClick={()=>{CameraIconClick()}} color="error"  badgeContent={1}>
          <CameraEnhanceIcon  
            sx={{
              color:'primary.main',
            }}
          />
        </Badge>
        <MusicSwitch />
      </Stack>
    </Stack>
  );
};

export default Navigation;
