import React, { useState, useEffect } from 'react';
import { useProgress } from '@react-three/drei';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import LoaderIcon1 from '../../img/SVG/loaderIcon1.svg'
import Avatar from '@mui/material/Avatar'
import { useMyContext } from '../../stateContext';
import LoaderIcon2 from "../../img/SVG/loaderIcon2.svg";
import LoaderIcon3 from "../../img/SVG/loaderIcon3.svg";
import LoaderIcon4 from "../../img/SVG/loaderIcon4.svg";
import LoaderIcon5 from "../../img/SVG/loaderIcon5.svg";
import LoaderIcon6 from "../../img/SVG/loaderIcon6.svg";

const Loaded = ({showBtn}) => {
    // const { active, progress, errors, item, loaded, total } = useProgress();
    // const [loading, setLoading] = useState(true);
    const [iconNum,setIconNum] = useState(1);

    const context = useMyContext();

    // console.log(showBtn)
    // useEffect(() => {
    //     if (!active && loaded === total) {
    //         setLoading(false); // 资源加载完成后停止显示 Loader
    //         // onLoaded(); // 调用 onLoaded 回调
    //         // showBtn = false;
    //     }
    //
    // }, [active, loaded, total]);

    let intervalId= null;
    useEffect(()=>{
        let i = 1;
        intervalId = setInterval(()=>{
            setIconNum(i);
            i++;
            if(i>5){i=1;}
        },400);
        return ()=>{
            clearInterval(intervalId);
            context.setLoader(false);
        }
    },[])

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            zIndex:10000,
            width:'100vw',
            position:'fixed',
            flexDirection: 'column',
            backgroundColor:'background.default',
        }}>

            <Avatar
                sx={{
                    width:{
                        xs:'4em',
                        sm:'4em',
                    },
                    height:{
                        xs:'auto',
                        sm:'auto',
                    },
                    borderRadius:'0',
                    objectFit: 'cover',
                }}
                alt=""
                src={
                    (iconNum === 1 && LoaderIcon1) ||
                    (iconNum === 2 && LoaderIcon2) ||
                    (iconNum === 3 && LoaderIcon3) ||
                    (iconNum === 4 && LoaderIcon4) ||
                    (iconNum === 5 && LoaderIcon5) ||
                    (iconNum === 6 && LoaderIcon6)
                }
            />


            <Typography
                variant="button"
                color="text.disabled"
            >{'100%'}
            </Typography>

            {showBtn && <Button
                variant='contained'
                onClick={()=>{context.setLoaded(true);}}
                sx={{cursor:'pointer'}}
            >
                <Typography color="text.primary" variant="button">
                    Explore
                </Typography>
            </Button>}


        </Box>
    );
};

export default Loaded;