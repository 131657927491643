import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

function Battery(props) {
  const { nodes, materials } = useGLTF("/Battery.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Battery_1.geometry}
        material={materials.Metal}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Battery_2.geometry}
        material={materials.anode}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Battery_3.geometry}
        material={materials.cathode}
      />
    </group>
  );
}

useGLTF.preload("/Battery.glb");

export default Battery
