import { useEffect } from 'react';
import { useGLTF,useTexture} from '@react-three/drei'
import * as THREE from 'three';
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";
import { useMyContext } from '../../stateContext';
import humidity from './textureImgs/humidity.png';
import temperature from './textureImgs/temperature.png';
import weight from './textureImgs/weight.png';
import defaultImg from './textureImgs/default.png';
import { useFrame } from '@react-three/fiber'

function Model({...props }) {
const { nodes, materials } = useGLTF("./Model.glb")

const seal = materials.Plastic_White.clone(); 
const LetMaterial = materials.Plastic_Orange.clone();
const DoorMaterial = materials['Glass-Black'].clone();

materials.DisplayUI.emissive=new THREE.Color(0x09DD44);
materials.DisplayUI.emissiveIntensity = 0; // 设置自发光强度为2倍

materials.Glass.transparent=true;
materials.Glass.depthTest=true;
materials.Glass.depthWrite= true;
materials.Glass.opacity=0.5;
materials.Glass.reflectivity=0.5;


const lightMaterail = materials['Light_Green '].clone();
lightMaterail.color.r = 1.0;
lightMaterail.color.g = 0.0;
lightMaterail.color.b = 0.0;

const [humidityMap, temperatureMap, weightMap,defaultMap] = useTexture([
    humidity,
    temperature,
    weight,
    defaultImg
])
// const defaultMap = materials.DisplayUI.map.clone();

const context = useMyContext();

//DIY后面的四个金属挂件螺铨
const diyHandlerMaterail = materials.Metal_Gold.clone();
diyHandlerMaterail.color.r = 1.0;
diyHandlerMaterail.color.g = 0.0;
diyHandlerMaterail.color.b = 0.0;


useGSAP(()=>{

  /* 首页鼠标移入移出 */
  if(!context.isBtnOpen){
    /* open按钮的移入移出事件 */
    gsap.to(context.Push_Ref.current.rotation,{
      duration:0.3,
      y:context.isPushMouseEnter ? Math.PI/6 : 0,
    })

    /* 出料口按钮的移入移出 */
    gsap.to(context.LetR_Ref.current.position,{
      duration:0.5,
      y:context.isLetMouseEnter ? 1.5: 1.346,
      ease: 'power2.inOut',
    })
    gsap.to(context.LetL_Ref.current.position,{
      duration:0.5,
      z:context.isLetMouseEnter ? 1.4: 1.188,
      ease: 'power2.inOut',
    })

      /* USB接口盖子 */
    gsap.to(context.TypeCA_Ref.current.position,{
      duration:0.5,
      z:context.isTypeC_Cap ? -2.8: -2.692,
      ease: 'power2.inOut',
    })

    /* 显示屏亮起 */
    gsap.to(context.DisplayUI_Ref.current.material,{
        duration:0.5,
        emissiveIntensity:context.isDisplayUIBright?0.1:0,
        ease: 'power2.inOut',
    })
    
  }else
  {
    /* 仓门开合 */
    gsap.to(context.Door_Ref.current.rotation,{
      duration:0.5,
      z:(()=>{
       if(context.isSpecification) return -1.2;
       else if(context.isSpace) return -1.5;
       else return 0;
      }) (),
      delay:(()=>{
        if(context.isSpecification) return 0.5;
        else if(context.isSpace) return 0.5;
        else if(!context.isSpace) return 0.5;
        else return 0;
       }) (),
    })  
      /* 仓门位移 */
    gsap.to(context.Door_Ref.current.position,{
      duration:1,
      x:context.isSeal ? 5: 0.932,
      ease: 'power2.inOut',
    })
    /* 密封圈位移 */
    gsap.to(context.GasketB_Ref.current.position,{
      duration:1,
      x:context.isSeal ? -2: 1.026,
      ease: 'power2.inOut',
    })
    gsap.to(context.GasketF_Ref.current.position,{
      duration:1,
      x:context.isSeal ? 2: 0.955,
      ease: 'power2.inOut',
    })
    if(!context.isLetMouseEnter){
        /* 出料口位移 */
        gsap.to(context.LetR_Ref.current.position,{
          duration:1,
          z:context.isSeal ? -3.052: -2.052,
          y:context.isSeal ? 2.346: 1.346,
          ease: 'power2.inOut',
        })
        gsap.to(context.LetL_Ref.current.position,{
          duration:1,
          z:context.isSeal ? 2.188 : 1.188,
          y:context.isSeal ? 3.114 : 2.114,
          ease: 'power2.inOut',
        })
        setTimeout(() => {
          gsap.to(context.GasketL_Ref.current.position,{duration:0.5,z:context.isSeal ?3.51: 2.748,ease: 'power2.inOut',})
          gsap.to(context.DisplayPanel_Ref.current.position,{duration:0.5,z:context.isSeal ? 3.5: 2.764,ease: 'power2.inOut'})
          gsap.to(context.DisplayLight_Ref.current.position,{duration:0.5,z:context.isSeal ? 0.75: -0.005,ease: 'power2.inOut'})
          gsap.to(context.DisplayUI_Ref.current.position,{duration:0.5,z:context.isSeal ? 3.45: 2.7,ease: 'power2.inOut',})
          gsap.to(context.DisplayButtonUP_Ref.current.position,{duration:0.5,z:context.isSeal ? 0.75: 0.018,ease: 'power2.inOut',})
          gsap.to(context.DisplayButtonSet_Ref.current.position,{duration:0.5,z:context.isSeal ? 0.75: 0.018,ease: 'power2.inOut',})
          gsap.to(context.DisplayButtonDown_Ref.current.position,{duration:0.5,z:context.isSeal ? 0.75: 0.018,ease: 'power2.inOut',})
        }, 500); // 0.5秒延迟执行
    }




    if(context.isSeal){
      gsap.to(context.GasketL_Ref.current.material.color,{
        duration:0.5,
        r:0,
        g:1,
        b:0.2,
        ease: 'power2.inOut',
        yoyo: true,
        repeat: -1
      })
      gsap.to(context.GasketB_Ref.current.material.color,{
        duration:0.5,
        r:0,
        g:1,
        b:0.2,
        ease: 'power2.inOut',
        yoyo: true,
        repeat: -1
      })
      gsap.to(context.GasketF_Ref.current.material.color,{
        duration:0.5,
        r:0,
        g:1,
        b:0.2,
        ease: 'power2.inOut',
        yoyo: true,
        repeat: -1
      })
    
      /* 出料口颜色 */
      gsap.to(context.LetR_Ref.current.material.color,{
        duration:0.5,
        r:0,
        g:1,
        b:0.2,
        ease: 'power2.inOut',
        yoyo: true,
        repeat: -1
      })
      gsap.to(context.LetL_Ref.current.material.color,{
        duration:0.5,
        r:0,
        g:1,
        b:0.2,
        ease: 'power2.inOut',
        yoyo: true,
        repeat: -1
      })
    }else{
      gsap.to(context.GasketL_Ref.current.material.color,{
        duration:0.5,
        r:1,
        g:1,
        b:1,
        ease: 'power2.inOut',
      })
      gsap.to(context.GasketB_Ref.current.material.color,{
        duration:0.5,
        r:1,
        g:1,
        b:1,
        ease: 'power2.inOut',
      })
      gsap.to(context.GasketF_Ref.current.material.color,{
        duration:0.5,
        r:1,
        g:1,
        b:1,
        ease: 'power2.inOut',
      })
    
      /* 出料口颜色 */
      gsap.to(context.LetR_Ref.current.material.color,{
        duration:0.5,
        r:0.83,
        g:0.103,
        b:0.009,
        ease: 'power2.inOut',
      })
      gsap.to(context.LetL_Ref.current.material.color,{
        duration:0.,
        r:0.83,
        g:0.103,
        b:0.009,
        ease: 'power2.inOut',
      })
    }

    if(!context.isBatteryShow){
      gsap.to(context.DryingDoorR_Ref.current.rotation,{duration:1,x:context.isSpace ? Math.PI/4*3 : Math.PI/2,ease: 'power2.inOut',delay:context.isSpace ? 0.5:0,})
      gsap.to(context.DryingDoorL_Ref.current.rotation,{duration:1,x:context.isSpace ? Math.PI/4 : Math.PI/2,ease: 'power2.inOut',delay:context.isSpace ? 0.5:0,})
      
      /* 透明 */
      gsap.to([context.BoxMain_Ref.current.material,
        context.GasketF_Ref.current.material,
        context.GasketB_Ref.current.material,
        context.Door_Ref.current.children[0].material,
        context.Door_Ref.current.children[1].material,
        context.Roller_Ref.current.material,
        context.Tray_Ref.current.material,
        context.ScrewB_Ref.current.material,
        context.PIS_Ref.current.material,
      ],
        {
        duration:1,
        opacity:context.isSpace ? 0.1: 1,
        ease: 'power2.inOut',
        delay:context.isSpace ? 0.5 : 0,
        onStart: function() {
          setTimeout(function() {
            if (context.isSpace) {
                context.BoxMain_Ref.current.material.transparent = true;
                context.Door_Ref.current.children[0].material.transparent = true;
                context.Door_Ref.current.children[1].material.transparent = true;
                context.Roller_Ref.current.material.transparent = true;
                context.Tray_Ref.current.material.transparent = true;
                context.ScrewB_Ref.current.material.transparent = true;
                context.GasketF_Ref.current.material.transparent = true;
                context.GasketB_Ref.current.material.transparent = true;
                context.PIS_Ref.current.material.transparent = true;
          }else if (!context.isSpace) {
                context.BoxMain_Ref.current.material.transparent = false;
                context.Door_Ref.current.children[0].material.transparent = false;
                context.Door_Ref.current.children[1].material.transparent = false;
                context.Roller_Ref.current.material.transparent = false;
                context.Tray_Ref.current.material.transparent = false;
                context.ScrewB_Ref.current.material.transparent = false;
                context.GasketF_Ref.current.material.transparent = false;
                context.GasketB_Ref.current.material.transparent = false;
                context.PIS_Ref.current.material.transparent = false;
            }
        }, 300); // 0.5秒后执行 onComplete
        },

      })

      gsap.to(context.Filament_Ref.current.position,{duration:1,x:context.isSpace?20:-0.151,ease: 'power2.inOut',delay:context.isSpace ? 0.5:0,onComplete:()=>{
            // if(!context.isBtnOpen && context.isSpace){
            //     context.Filament_Ref.current.position.x = -0.151;
            // }
          }})

    }

  

  }


if(context.isUITabShow)
{
    if(context.isHumidity){
        gsap.to(context.DisplayLight_Ref.current.material.color,{
            duration:0.5,
            r:0,
            g:0,
            b:0,
            ease: 'power2.inOut',
            yoyo: true,
            repeat: -1
        })
    }
}

/* USB动画 */

gsap.to(context.TypeCA_Ref.current.position,{
  duration:0.5,
  z:context.isUSBTabShow || context.isTypeC_Cap ? -2.8: -2.692,
  ease: 'power2.inOut',
})
gsap.to(context.TypeCA_Ref.current.rotation,{
  duration:0.5,
  y:context.isUSBTabShow || context.isTypeC_Cap ? Math.PI: 0,
  x:context.isUSBTabShow || context.isTypeC_Cap ? Math.PI/3*2: Math.PI / 2,
  ease: 'power2.inOut',
})

/* DIY动画 */
gsap.to(context.ScrewB_Ref.current.position,{
  duration:1,
  x:context.isDIYTabShow?-1.5:-1.102,
  ease: 'power2.inOut',
})


    /* 出料口翻盖动画 */
  if(context.isLetTabShow){
      const tl =gsap.timeline();
      tl.to(context.LetL_Ref.current.rotation, { duration:1,x:-1.5 ,z:-Math.PI,ease: 'power2.inOut'})
      .to(context.LetR_Ref.current.rotation, { duration:1,x:-2.6 ,z:-Math.PI,ease: 'power2.inOut'},'<')
      .to(context.LetL_Ref.current.position, { duration:1,x:0,z:1.46,ease: 'power2.inOut'},'<')
      .to(context.LetR_Ref.current.position, { duration:1,x:-0.079,y:1.6,z:-2,ease: 'power2.inOut'},'<');
  }else{
      const tl =gsap.timeline();
      tl.to(context.LetL_Ref.current.rotation,{duration:1,x:0,y:0,z:0})
       .to(context.LetL_Ref.current.position,{duration:1,x:-0.074,y:2.114,z:1.188},'<')
       .to(context.LetR_Ref.current.rotation,{duration:1,x:-1.455,y:0,z:0},'<')
       .to(context.LetR_Ref.current.position,{duration:1,x:-0.079,y:1.346,z:-2.052},'<');
  }


}, [context])





    useEffect(()=>{
        gsap.to(context.BoxMain_Ref.current.material,{
          duration:0.5,
          opacity:(context.isBatteryShow || context.isPowerTabShow) ? 0.3 : 1,
          ease: 'power2.inOut',
          transparent:(context.isBatteryShow || context.isPowerTabShow),
        })
    },[context.isBatteryShow,context.isPowerTabShow]);


if(context.isHumidity){
    materials.DisplayUI.map = humidityMap;
}else if(context.isTemperature){
    materials.DisplayUI.map = temperatureMap;
}else if(context.isWeight){
    materials.DisplayUI.map = weightMap;
}else{
    materials.DisplayUI.map = defaultMap;
}


useEffect(()=>{
    gsap.to([
            context.BoxMain_Ref.current.material,
            context.GasketF_Ref.current.material,
            context.GasketB_Ref.current.material,
            context.Door_Ref.current.children[0].material,
            context.Door_Ref.current.children[1].material,
            context.ScrewB_Ref.current.material,
            context.PIS_Ref.current.material,
            context.Filament_Ref.current.children[1].material,
            context.Filament_Ref.current.children[2].material,
            context.Filament_Ref.current.children[3].material,
        ],
        {
            duration:1,
            opacity:context.isWeight? 0.1 : 1,
            ease: 'power2.inOut',
            onStart: function() {
              if (context.isWeight) {
                context.BoxMain_Ref.current.material.transparent = true;
                context.Door_Ref.current.children[0].material.transparent = true;
                context.Door_Ref.current.children[1].material.transparent = true;
                context.ScrewB_Ref.current.material.transparent = true;
                context.GasketF_Ref.current.material.transparent = true;
                context.GasketB_Ref.current.material.transparent = true;
                context.PIS_Ref.current.material.transparent = true;
                context.Filament_Ref.current.children[1].material.transparent = true;
                context.Filament_Ref.current.children[2].material.transparent = true;
                context.Filament_Ref.current.children[3].material.transparent = true;
             }
            },
            onComplete:function(){
                 if (!context.isWeight) {
                    context.BoxMain_Ref.current.material.transparent = false;
                    context.Door_Ref.current.children[0].material.transparent = false;
                    context.Door_Ref.current.children[1].material.transparent = false;
                    context.ScrewB_Ref.current.material.transparent = false;
                    context.GasketF_Ref.current.material.transparent = false;
                    context.GasketB_Ref.current.material.transparent = false;
                    context.PIS_Ref.current.material.transparent = false;
                    context.Filament_Ref.current.children[1].material.transparent = false;
                    context.Filament_Ref.current.children[2].material.transparent = false;
                    context.Filament_Ref.current.children[3].material.transparent = false;
                }
            }
        })

},[context.isHumidity,context.isUITabShow,context.isWeight]);


    useGSAP(()=>{
        if(context.isDIYTabShow){
            gsap.to(context.ScrewB_Ref.current.material.color,{
                duration:0.5,
                r:0.75,
                g:0.57,
                b:0.22,
                ease: 'power2.inOut',
                yoyo:true,
                repeat:-1
            })
        }
    },[context.isDIYTabShow])

    useFrame(()=>{
        if(( (!context.isBtnOpen && !context.isSpace && !context.isWeight && !context.isUITabShow) || (context.isBtnOpen && context.isSpecification) ) && !context.isBatteryShow && !context.isPowerTabShow){
            context.Filament_Ref.current.position.x = -0.151;
            context.DryingDoorR_Ref.current.rotation.x = Math.PI/2;
            context.DryingDoorL_Ref.current.rotation.x = Math.PI/2;

            context.BoxMain_Ref.current.material.transparent = false;
            context.Door_Ref.current.children[0].material.transparent = false;
            context.Door_Ref.current.children[1].material.transparent = false;
            context.Roller_Ref.current.material.transparent = false;
            context.Tray_Ref.current.material.transparent = false;
            context.ScrewB_Ref.current.material.transparent = false;
            context.GasketF_Ref.current.material.transparent = false;
            context.GasketB_Ref.current.material.transparent = false;
            context.PIS_Ref.current.material.transparent = false;
        }
        if(context.isUITabShow && context.isWeight){
            context.BoxMain_Ref.current.material.transparent = true;
            context.Door_Ref.current.children[0].material.transparent = true;
            context.Door_Ref.current.children[1].material.transparent = true;
            context.ScrewB_Ref.current.material.transparent = true;
            context.GasketF_Ref.current.material.transparent = true;
            context.GasketB_Ref.current.material.transparent = true;
            context.PIS_Ref.current.material.transparent = true;
            context.Filament_Ref.current.children[1].material.transparent = true;
            context.Filament_Ref.current.children[2].material.transparent = true;
            context.Filament_Ref.current.children[3].material.transparent = true;
        }
    })

  return (
    <group {...props}  dispose={null}>

  {/* 耗材 */}
  <group ref={context.Filament_Ref} position={[-0.151, 0, 0.004]}>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Filament_1.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Filament_2.geometry}
          material={materials.Filament_Front}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Filament_3.geometry}
          material={materials.Filament_Side}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Filament_4.geometry}
          material={materials.Glass_Frosted}
        />
      </group>

{/* 贴纸 */}
      <mesh
        ref={context.PIS_Ref}
        castShadow
        receiveShadow
        geometry={nodes.PIS.geometry}
        material={materials.PIS}
        position={[0, 0, 0.002]}
      />

  {/* 前密封圈 */}
  <mesh
        ref={context.GasketF_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Gasket_F.geometry}
        material={seal}
        position={[0.955, 0.005, 0.012]}
        rotation={[-Math.PI, 0, -Math.PI / 2]}
        scale={[-2.104, -0.03, -2.104]}
      />
      

{/* 后密封圈 */}
      <mesh
        ref={context.GasketB_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Gasket_B.geometry}
        material={seal}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />

   
{/* 仓门垫片 */}
      <mesh
        ref={context.DoorSeal_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DoorSeal.geometry}
        material={nodes.DoorSeal.material}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />

{/* 仓门螺栓 */}
      <mesh
        ref={context.DoorDolt_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DoorDolt.geometry}
        material={materials.Metal}
        position={[0, 0, 0.008]}
        visible={!context.isShowUI}
      />

{/* 后硅胶垫片 */}
      <mesh
        ref={context.CapD_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Cap_D.geometry}
        material={materials["Plastic_Gray "]}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />

{/* 底部硅胶垫片 */}
      <mesh
        ref={context.CapB_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Cap_B.geometry}
        material={materials.Plastic_White}
        position={[0, 0, -0.002]}
      />

{/* 主体 */}
      <mesh
        ref={context.BoxMain_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Box_Main.geometry}
        material={materials.LOGO}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />
 

{/* 耗材支撑 */}
      <mesh
        ref={context.Tray_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Tray.geometry}
        material={materials["Plastic_Gray "]}
        position={[-0.011, 0, 0]}
      />
       
 {/* 后背螺丝 */}
      <mesh
        ref={context.ScrewB_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Screw_B.geometry}
        material={context.isDIYTabShow ? diyHandlerMaterail : materials.Metal_Gold}
        position={[-1.102, 0.886, -0.992]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.043, 0.056, 0.043]}
      />

{/* 称重滚轴 */}
      <mesh
        ref={context.Roller_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Roller.geometry}
        material={materials.Metal}
        position={[-0.146, -1.858, 0.013]}
      />
{/* Type-C盖子 */}
      <mesh
      ref={context.TypeCA_Ref}
        castShadow
        receiveShadow
        geometry={nodes["Type-C_A"].geometry}
        material={materials["Plastic_Gray "]}
        position={[-0.067, -0.878, -2.692]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />
{/* 仓门按钮 */}
      <mesh
        ref={context.Push_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Push.geometry}
        material={materials.Plastic_Orange}
        position={[0.917, 2.251, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
        visible={!context.isShowUI}
      />

{/* 出料口 */}
      <mesh
        ref={context.LetR_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Let_R.geometry}
        material={LetMaterial}
        position={[-0.079, 1.346, -2.052]}
        rotation={[-1.455, 0, 0]}
        visible={!context.isShowUI}
      />

      <mesh
        ref={context.LetL_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Let_L.geometry}
        material={LetMaterial}
        position={[-0.074, 2.114, 1.188]}
        visible={!context.isShowUI}
      /> 




{/* 干燥剂仓门 */}
      <mesh
        ref={context.DryingDoorR_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DryingDoor_R.geometry}
        material={materials.Plastic_White}
        position={[0.015, -1.665, -1.246]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />
      <mesh
      ref={context.DryingDoorL_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DryingDoor_L.geometry}
        material={materials.Plastic_White}
        position={[0.015, -1.667, 1.257]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />


{/* Type-C接口 */}
      <group visible={!context.isShowUI} ref={context.TypeCB_Ref} position={[-0.068, -0.681, -2.744]} scale={[1, 0.924, 1]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Type-C_B_1"].geometry}
          material={materials.Metal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Type-C_B_2"].geometry}
          material={materials["Metal_Black "]}
        />
      </group>

{/* 显示屏UI */}
      <mesh
        ref={context.DisplayUI_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DisplayUI.geometry}
        material={materials.DisplayUI}
        position={[-0.049, -0.018, 2.7]}
        rotation={[-1.785, 0, 0]}
        scale={[0.561, 0.363, 0.351]}
      />

{/* 显示屏面板 */}
      <group
        ref={context.DisplayPanel_Ref}
        position={[-0.044, -0.203, 2.764]}
        rotation={[-0.218, 0.019, 0]}
        scale={[0.76, 0.761, 0.008]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DisplayPanel_1.geometry}
          material={materials['Glass']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DisplayPanel_2.geometry}
          material={materials["Glass-Black"]}
        />
      </group>

      <mesh
        ref={context.GasketL_Ref}
        castShadow
        receiveShadow
        geometry={nodes.Gasket_L.geometry}
        material={seal}
        position={[-0.044, -0.199, 2.748]}
        rotation={[-0.218, 0.013, 0.003]}
        scale={[1.03, 1.044, 1.03]}
      />

{/* 显示灯 */}
      <mesh
        ref={context.DisplayLight_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DisplayLight.geometry}
        material={context.isHumidity ? lightMaterail : materials['Light_Green ']}
        position={[0, 0, -0.005]}
      />
{/* 上按钮 */}
      <mesh
        ref={context.DisplayButtonUP_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DisplayButton_UP.geometry}
        material={materials.Button_UP}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />

{/* 设置按钮 */}
      <mesh
        ref={context.DisplayButtonSet_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DisplayButton_Set.geometry}
        material={materials.Button_Set}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />
{/* 下按钮 */}
      <mesh
        ref={context.DisplayButtonDown_Ref}
        castShadow
        receiveShadow
        geometry={nodes.DisplayButton_Down.geometry}
        material={materials.Button_Down}
        position={[1.026, -0.105, 0.018]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.199}
      />


{/* 仓门 */}
<group ref={context.Door_Ref} position={[0.932, -2.216, -0.017]} scale={1.014}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Door_1.geometry}
          material={materials.Plastic_White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Door_2.geometry}
          material={DoorMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Door_3.geometry}
          material={materials.Glass}
        />
      </group>
    </group>

    
  );
}


useGLTF.preload("/Model.glb");

export default Model