import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box'
import Slogan from '../Model/Slogan.svg'
import gsap from 'gsap'
import "./marquee.css"
import { useMyContext } from '../../stateContext';
import { useGSAP } from '@gsap/react';
import { Translate } from '@mui/icons-material';

function Marquee(){
    const context = useMyContext();

    useGSAP(()=>{
        if(context.isLoaded){
            gsap.to(".SloganContainer",{duration:1,transform: 'translateY(0%)', opacity:1,ease: 'power2.inOut'})
        }

        gsap.to(".Slogan1",{
            duration:context.fovmd? 40 : 30,
            ease:'none',
            x:context.fovmd ?"-=150vw" : "-=300vw",
            modifiers: {
                
                x: gsap.utils.unitize(x => {
                    const divisor = context.fovmd ? 150 : 300;
                    return parseFloat(x) % divisor ;
                }) //force x value to be between 0 and 500 using modulus
              },
              repeat: -1
        })
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          

    },[context.isLoaded, context.fovmd])


    return (
        
        <Box className='SloganContainer'
        sx={{
          width:{
            xs:'600vw',
            sm:'300vw',
          }
        }}
        >
            <Box className='Slogan1' 
                sx={{
                    width:{
                      xs:'300vw',
                      sm:'150vw',
                    }
                }}
            > 
                <img src={`${Slogan}`} alt="slogan" />
            </Box>
            <Box className='Slogan1' 
                sx={{
                    width:{
                        xs:'300vw',
                        sm:'150vw',
                    }
                }}
            > 
                <img src={`${Slogan}`} alt="slogan" />
            </Box>
        </Box>
         
    );

}

export default Marquee;