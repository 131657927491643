import { useMemo,useCallback } from 'react'
import { useGLTF,Merged} from '@react-three/drei'
import gsap from 'gsap'
import Model from './Model';
import PopComponent from '../Pop/popComponent';
import SizeuiComponent from './SizeuiComponent';
import DesiccantComponent from './Desiccant';
import Battery from './Battery';
import USBLine from './USBLine';
import LetOut from './LetOut';
import { useMyContext } from '../../stateContext';


function MergedModel(props,){
    const { nodes} = useGLTF("./Model.glb")
    const context = useMyContext();

    const buttonText = [
        "Outlet A",
        "Outlet B",
        "Smart Reminders",
        "Open",
        "Type-C Port",
        "Mounting Hole Design",
        "Low Power : Battery Port",
    ];

    // const BoxRef=useRef();




    const MergedModels = useMemo(()=>({
        BoxMain:nodes.Box_Main,
        CapB:nodes.Cap_B,
        CapD:nodes.Cap_D,
        ScrewB:nodes.Screw_B,
        DoorDolt:nodes.DoorDolt,
        DoorSeal:nodes.DoorSeal,
        PIS:nodes.PIS,

        Filament:nodes.Filament,

        DisplayButtonUP:nodes.DisplayButton_UP,
        DisplayButtonDown:nodes.DisplayButton_Down,
        DisplayButtonSet:nodes.DisplayButton_Set,
        DisplayLight:nodes.DisplayLight,
        DisplayPanel1:nodes.DisplayPanel_1,
        DisplayPanel2:nodes.DisplayPanel_2,
        DisplayUI:nodes.DisplayUI,

        Push:nodes.Push,
        DryingDoorL:nodes.DryingDoor_L,
        DryingDoorR:nodes.DryingDoor_R,
        LetL:nodes.Let_L,
        LetR:nodes.Let_R,
        TypeC_A:nodes["Type-C_A"],
        TypeC_B1:nodes["Type-C_B_1"],
        TypeC_B2:nodes["Type-C_B_2"],
        DoorGroup:nodes.Door,
        Tray:nodes.Tray,
        Roller:nodes.Roller,
        GasketB:nodes.Gasket_B,
        GasketF:nodes.Gasket_F,
    })
    )

    const openBtnClick =useCallback (()=>{
        context.setBtnOpen(true);
        context.setVisibleSizeUi(true);
        context.setSpecification(true);
        context.setTabOpacity(true);
        context.setAutoRotate(false);
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})
        const tl =gsap.timeline();
        tl
        .to(context.cameraRef.current.position, { duration:1,x:context.modelLabel.CameraX, y:3, z:context.modelLabel.CameraZ ,ease: 'power2.inOut'})
        .to(context.OrbitRef.current.target, { duration:1,x:context.modelLabel.OrbitX, y:context.modelLabel.OrbitY, z:context.modelLabel.OrbitZ ,ease: 'power2.inOut'},"<")
        .to(context.Push_Ref.current.rotation, { duration:0.3,y:Math.PI/4 },"<")
        .to(context.Push_Ref.current.rotation, { duration:0.3,y:0 })
    },[])


    const openMouseEnter = useCallback((pop)=>{

        context.setAutoRotate(false);
       
        switch(pop){
            case 'open':
                context.setPushMouseEnter(true)
            break; 
            case 'letOut':
                context.setLetMouseEnter(true)
            break; 
            case 'UI':
                context.fovmd && context.setDisplayUIBright(true);
            break; 
            case 'TypeC_Cap':
                context.setTypeC_Cap(true)
            break;
            case 'Battery':
                context.setBatteryShow(true)
            break;
            default:
            // 处理未匹配到的情况，可以是一个空的语句块或其他逻辑
            break;
        }
       
    },[context])

    const openMouseLeave=useCallback((pop)=>{

        context.setAutoRotate(true);

        switch(pop){
            case 'open':
                context.setPushMouseEnter(false)
            break; 
            case 'letOut':
                context.setLetMouseEnter(false)
            break; 
            case 'UI':
                context.setDisplayUIBright(false)
            break;
            case 'TypeC_Cap':
                context.setTypeC_Cap(false)
            break;
            case 'Battery': 
                context.setBatteryShow(false)
            break;
            default:
            // 处理未匹配到的情况，可以是一个空的语句块或其他逻辑
            break;
        }
       
    },[context])
    
    const openMouseClick= useCallback((pop)=>{
        context.setAutoRotate(false);
        switch(pop){
            case 'UItab':
                context.setUITabShow(true);
                smartRemindsClick();
            break;

            case 'USBtab':
                context.setUSBTabShow(true);
                USBClick();
            break;
            case 'PowerTab':
                context.setPowerTabShow(true)
                PowerClick();
            break;
            case 'LetTab':
                context.setLetTabShow(true);
                LetOutClick();
            break;
            case 'DIYTab':
                context.setDIYTabShow(true)
                DIYClick();
            break;
            default:
            // 处理未匹配到的情况，可以是一个空的语句块或其他逻辑
            break;
        }
    },[context])

    const smartRemindsClick =useCallback (()=>{
        // context.setTabOpacity(true);
        context.setAutoRotate(false);
        context.setHumidity(true);
        context.setWeight(false);
        context.setTemperature(false);
        context.setShowUI(true);
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})
        const tl =gsap.timeline({onStart:()=>{context.setAutoRotate(false);},onComplete:()=>{context.setHumidityLight(true);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.modelLabel.CameraX2, y:-0.6, z:context.modelLabel.CameraZ2 ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.modelLabel.OrbitX2, y:context.modelLabel.OrbitY2, z:context.modelLabel.OrbitZ2 ,ease: 'power2.inOut'},"<")
    },[])

    const USBClick =useCallback (()=>{
        // context.setTabOpacity(true);
        context.setUSBTabShow(true)
        context.setAutoRotate(false);
        context.setTypeC_Cap(true);
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})
        const tl =gsap.timeline({onStart:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.USBCameraX, y:1, z:context.USBCameraZ ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.USBOrbitX, y:context.USBOrbitY, z:context.USBOrbitZ ,ease: 'power2.inOut'},"<")
    },[])

    const PowerClick =useCallback (()=>{
        context.setAutoRotate(false);
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})
        // context.setBatteryShow(true)
        const tl =gsap.timeline({onStart:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.PowerCameraX, y:-3, z:context.PowerCameraZ ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.PowerOrbitX ,y:context.PowerOrbitY, z:context.PowerOrbitZ ,ease: 'power2.inOut'},"<")

    },[])


    const LetOutClick =useCallback (()=>{
        // context.setLetAllow(true)
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})

        const tl =gsap.timeline({onStart:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.LetOutCameraX, y:context.LetOutCameraY, z:context.LetOutCameraZ ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.LetOutOrbitX ,y:context.LetOutOrbitY, z:context.LetOutOrbitZ ,ease: 'power2.inOut'},"<");
    },[]);



    const DIYClick =useCallback (()=>{
        context.setCameraPosition({x:context.cameraRef.current.position.x,y:context.cameraRef.current.position.y,z:context.cameraRef.current.position.z})
        const tl =gsap.timeline({onStart:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.DIYCameraX, y:0, z:context.DIYCameraZ ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.DIYOrbitX ,y:context.DIYOrbitY, z:context.DIYOrbitZ ,ease: 'power2.inOut'},"<")

    },[])

    return(
        <group ref={context.BoxRef} {...props} dispose={null} scale={[0.2, 0.2, 0.2]}>

            {!(context.isBtnOpen || context.isShowUI || context.isUSBTabShow || context.isPowerTabShow || context.isLetTabShow || context.isDIYTabShow) &&
                <>
                <PopComponent position={[-0.07, 2, -2]} onClick={()=>{openMouseClick('LetTab')}} onMouseEnter={()=>{openMouseEnter('letOut')}} onMouseLeave={()=>{openMouseLeave('letOut')}} text={buttonText[0]} occludeDefault={true}  />
                <PopComponent position={[-0.07, 2, 2]} onClick={()=>{openMouseClick('LetTab')}} onMouseEnter={()=>{openMouseEnter('letOut')}} onMouseLeave={()=>{openMouseLeave('letOut')}} text={buttonText[1]}  occludeDefault={false} />
                <PopComponent position={[-0.07, 0.5, 2.85]} onClick={()=>{openMouseClick('UItab')}} onMouseEnter={()=>{openMouseEnter('UI')}} onMouseLeave={()=>{openMouseLeave('UI')}} text={buttonText[2]}  occludeDefault={false}  />
                <PopComponent onClick={()=>{openBtnClick()}}  occludeDefault={false} onMouseEnter={()=>{openMouseEnter('open')}} onMouseLeave={()=>{openMouseLeave('open')}} position={[1.3, 2.5, 0]} text={buttonText[3]} />
                <PopComponent position={[-0.07, -0.4, -3.1]} onClick={()=>{openMouseClick('USBtab');}} onMouseEnter={()=>{openMouseEnter('TypeC_Cap')}} onMouseLeave={()=>{openMouseLeave('TypeC_Cap')}} text={buttonText[4]} occludeDefault={(context.popIndex === 4) ? false : true}  />
                <PopComponent onClick={()=>{openMouseClick('DIYTab');}} onMouseEnter={()=>{openMouseEnter('DIYTab')}} onMouseLeave={()=>{openMouseLeave('DIYTab')}} position={[-1.4, 0, 0]} text={buttonText[5]}  occludeDefault={(context.popIndex === 5) ? false : true} />
                <PopComponent onClick={()=>{
                                openMouseClick('PowerTab');
                              }}
                              onMouseEnter={()=>{
                                  openMouseEnter('Battery');
                              }}
                              onMouseLeave={()=>{
                                  openMouseLeave('Battery');
                              }}
                              position={[0, -3.2, 0]} text={buttonText[6]}  occludeDefault={true} />
                </>
            }
            {!context.isWeight && !context.isSpace && <Battery />}

            <Merged meshes={MergedModels} {...props}>          
                {(MergedModels) => <Model MergedModels={MergedModels} />}
            </Merged>
            {context.visibleSizeUi && <SizeuiComponent />}

            <DesiccantComponent />
            {context.isUSBTabShow && <USBLine />}
            {context.isLetTabShow && <LetOut />}
            
            
        </group>
    )
}
export default MergedModel