import * as React from 'react';
import {forwardRef,useRef,useCallback} from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';

const LabTabs = forwardRef(({isTabRun, ...props },ref)=> {
  const context = useMyContext();
  // const [isClickBack, setClickBack] = useState(true);
  // const fovsm = useMediaQuery(theme.breakpoints.down('sm'));
  // const fovmd = useMediaQuery(theme.breakpoints.up('md'));
  // const fov = fovsm ? 'vertical' : 'horizontal';


  const { tabsRef,backRef } = ref || {};
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
      if(context.isSpecification || context.isSeal || context.isSpace ){
          setValue(newValue);
          switch (newValue) {
              case '1':Specification();break;
              case '2':Seal();break;
              case '3':Space();break;
          }
      }
  };
  const TabRef =useRef();

  const backAnim = useCallback(()=>{
          context.setTabOpacity(false);
          context.setVisibleSizeUi(false);
          context.setAutoRotate(true);
          context.setSpecification(false);
          context.setSeal(false);
          context.setSpace(false);
          context.setShowDesiccant(false);
          context.setGridConfig({...context.gridConfig,fadeDistance:12});
          gsap.to(context.OrbitRef.current.target, { duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'})
          gsap.to(context.cameraRef.current.position, {  duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut',onComplete:()=>{context.setBtnOpen(false);}})
          gsap.to('.openTab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setBtnOpen(false)}})

  },[]);

  const Specification=useCallback(()=>{

    context.setVisibleSizeUi(true);//尺寸组件出现
    context.setSpecification(true);
    context.setSeal(false);
    context.setSpace(false);
    context.setShowDesiccant(false);
    context.setGridConfig({...context.gridConfig,fadeDistance:12});
    gsap.to(context.cameraRef.current.position, { duration:1,x:context.tab.CameraX1, y:3, z:context.tab.CameraZ1,ease: 'power2.inOut'})
    gsap.to(context.OrbitRef.current.target, { duration:1,x:context.tab.OrbitX1, y:context.tab.OrbitY1, z:context.tab.OrbitZ1 ,ease: 'power2.inOut'})
  },[])

  const Seal=useCallback(()=>{

    context.setVisibleSizeUi(false);
    context.setSeal(true);
    context.setSpace(false);
    context.setSpecification(false);
    context.setShowDesiccant(false);
    context.setGridConfig({...context.gridConfig,fadeDistance:12});
    gsap.to(context.cameraRef.current.position, { duration:1,x:context.tab.CameraX2, y:1, z:context.tab.CameraZ2,ease: 'power2.inOut'})
    gsap.to(context.OrbitRef.current.target, { duration:1,x:context.tab.OrbitX2, y:context.tab.OrbitY2, z:context.tab.OrbitZ2 ,ease: 'power2.inOut'})
  },[])

  const Space=useCallback(()=>{
    context.setSeal(false);
    context.setSpace(true);
    context.setSpecification(false);
    context.setVisibleSizeUi(false);
    context.setGridConfig({...context.gridConfig,fadeDistance:5});
    gsap.to(context.cameraRef.current.position, { duration:1,x:context.tab.CameraX3, y:2, z:context.tab.CameraZ3,ease: 'power2.inOut'});
    gsap.to(context.OrbitRef.current.target, { duration:1,x:context.tab.OrbitX3, y:context.tab.OrbitY3, z:context.tab.OrbitZ3 ,ease: 'power2.inOut'});

  },[])

  useGSAP(()=>{
    if(context.isBtnOpen){
      gsap.to('.openTab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
      gsap.to('.card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
    }
  },[context.isBtnOpen])



  return (

    <Box 
    ref={TabRef}
    className='openTab'
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-start',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
      <Button 
        ref={backRef}
        sx={{
          marginBottom:'3vh',
        }}
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        onClick={
            ()=>{
                if(context.isSpecification || context.isSeal || context.isSpace ){
                    backAnim();
                }else{
                    return null;
                }
            }
        }
      >
        <Typography color="text.primary" variant="button">
          BACK
        </Typography>
      </Button>

      <Box
      className= 'card'
          sx={{
            width:{
              xs:'100%',
              sm:'40vw',
            },
            boxSizing:'border-box',
            display:'flex',
            flexDirection:{
              xs:'column',
              sm:'row',
            },
            alignItems: 'center',
            background: 'rgba(251, 251, 253, 0.1)',
            boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
            webkitBackdropFilter: ' blur(0px)',
            backdropFilter: 'blur(0px)',
            padding:{
              xs:'1rem',
              sm:'2rem',
            },
            borderRadius:{
              xs:'10px',
              sm:'20px',
            },
          }}
        >
          <TabContext
          ref={tabsRef} value={value} >
            
            <Box
              sx={{
                width:{
                  xs:'100%',
                  sm:'25%',
                },
              }}
            >
              <Tabs 
                  onChange={handleChange} //点击事件
                  orientation={context.tab.fov}
                  value={value}
                  sx={{
                    display: 'flex',
                  }}
              >
                    <Tab  sx={{ flex: 1 }} label={<Typography variant="button">Specification</Typography>} value="1" />
                    <Tab  sx={{ flex: 1 }} label={<Typography variant="button">Seal</Typography>} value="2" />
                    <Tab  sx={{ flex: 1 }} label={<Typography variant="button">Space</Typography>} value="3" />
              </Tabs>
            </Box>
            
            <Box
              sx={{
                marginTop:{
                  xs:'1rem',
                  sm:'0',
                },
                marginLeft:{
                  xs:'0',
                  sm:'2rem',
                },
                width:{
                  xs:'100%',
                  sm:'75%',
                },
              }}
            >
              <TabPanel value="1">
              <Typography color="text.primary" gutterBottom variant="h4">Suitable For Standard 1kg Filament-Spool</Typography>
                  <Typography color="text.primary" variant="body2">Maximum Spool-size: Φ204mm*70mm, suitable for almost all filament spools available on the market.</Typography>
              </TabPanel>

              <TabPanel value="2">
              <Typography color="text.primary" gutterBottom variant="h4">Airtight Sealing</Typography>
                  <Typography color="text.primary" variant="body2">Multiple sealing components ensure that the filament dryer is not contaminated with humid outside air. Even in a very humid environment (50%-70% RH), the CoCoon can maintain a humidity below 10% RH for two weeks and even after three weeks the humidity does not go over 15% RH.</Typography>
              </TabPanel>

              <TabPanel value="3">
              <Typography color="text.primary"  gutterBottom variant="h4">Extra-Large Desiccant Storage Space</Typography>
                  <Typography color="text.primary"  variant="body2">The extra-large desiccant storage was specially designed to optimally keep your filaments in a dry enviroment and is capable to hold up to 400g of desiccants.</Typography>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>

      </Box>
      
    </Box>
  );
})

export default LabTabs