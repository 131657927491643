import * as React from 'react';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';

const TabTYPEC = ({ ...props })=> {
  const context = useMyContext();

const USBBackClick =()=>{
  context.setTypeC_Cap(false);
  context.setAutoRotate(true);
  context.setPopIndex(4);
  const tl =gsap.timeline({});
  tl
  .to(context.USBLineRef.current.position,{duration:1,z:-3.35,ease: 'power2.inOut'})
  .to(context.OrbitRef.current.target, {duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'},"<")
  .to(context.cameraRef.current.position, {duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut'},"<")
  .to(context.USBLineRef.current.children[0].material,{duration:1,opacity:0,ease: 'power2.inOut'},"<0.5")
  .to(context.USBLineRef.current.children[1].material,{duration:1,opacity:0,ease: 'power2.inOut'},"<") 
  gsap.to('.USBTab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setUSBTabShow(false);}})
}

useGSAP(()=>{
  if(context.isUSBTabShow){
    gsap.to('.USBTab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
    gsap.to('.USBTab_card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
  }
},[context.isUSBTabShow])
  return (
    <Box 
    className='USBTab'
    ref={context.USBTab}
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-end',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(-20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
        <Button 
          sx={{
            marginBottom:'3vh',
          }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick = {()=>{
              if(!context.isAutoRotate){  //加上该条件可以使back按钮不致执行很多次
                  USBBackClick();
              }
          }}
        >
          <Typography color="text.primary" variant="button">
            BACK
          </Typography>
        </Button>

        <Box
        className='USBTab_card'
            sx={{
              width:{
                xs:'100%',
                sm:'40vw',
              },
              boxSizing:'border-box',
              display:'flex',
              flexDirection:'column',
              alignItems: 'center',
              background: 'rgba(251, 251, 253, 0.1)',
              boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
              webkitBackdropFilter: ' blur(0px)',
              backdropFilter: 'blur(0px)',
              padding:{
                xs:'1rem',
                sm:'2rem',
              },
              borderRadius:{
                xs:'10px',
                sm:'20px',
              },
            }}
        >
          <Typography color="text.primary"  gutterBottom variant="h4">
            USB Type C connection to transmit real time data to your devices
          </Typography>
          
          <Typography color="text.primary"  variant="body2">
            A Type-C USB connector can transmit the CoCoon data to your printer or computer using a specified serial protocol. With this feature your filament monitoring system becomes more intuitive. Using a Type-C USB connection, CoCoon can be recharged to extend its battery life.
          </Typography>

        </Box>

      </Box>
      
    </Box>
  );
}

export default TabTYPEC