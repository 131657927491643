import React, { useRef,createContext, useState, useContext } from 'react';
import { useMediaQuery } from '@mui/material'
import theme from './theme'

// 创建上下文对象（使用 PascalCase 命名约定）
const MyContext = createContext();

// 上下文 Provider 组件
export const MyContextProvider = ({ children }) => {
  // 定义和使用状态
  const [isLoaded,setLoaded] = useState(false);  //是否loaded
  const [isLoader,setLoader] = useState(false);  //是否loaded
  const [cameraPosition, setCameraPosition] = useState({ x: 5, y: 1, z: 5 });//记录相机位置
  const [isAutoRotate, setAutoRotate] = useState(false);    // 自旋转停止
  const [isBtnOpen, setBtnOpen] = useState(false);
  const [visibleSizeUi, setVisibleSizeUi] = useState(false);
  const [isBack, setBack] = useState(null); // open返回按钮点击动画
  const [isSpecification, setSpecification] = useState(false); //
  const [isSeal, setSeal] = useState(false); //
  const [isSpace, setSpace] = useState(false); //
  const [isTabOpacity, setTabOpacity] = useState(false);  //tabs的透明度变化
  const [showDesiccant, setShowDesiccant] = useState(false);//干燥剂

  const [isPushMouseEnter, setPushMouseEnter] = useState(false);//push的移入移出
  const [isLetMouseEnter, setLetMouseEnter] = useState(false);//出料口的移入移出
  const [isDisplayUIBright, setDisplayUIBright] = useState(false);//显示屏的亮起
  const [isTypeC_Cap, setTypeC_Cap] = useState(false);//USB盖子的移入移出
  const [isBatteryShow, setBatteryShow] = useState(false);//电池的出现与消失

  const [isUITabShow, setUITabShow] = useState(false);//UItab组件的出现与消失状态
  const [isUSBTabShow, setUSBTabShow] = useState(false);//USBtab组件的出现与消失状态
  const [isPowerTabShow, setPowerTabShow] = useState(false);//电池tab组件的出现与消失状态
  const [isLetTabShow, setLetTabShow] = useState(false);//出料口tab组件的出现与消失状态
  const [isDIYTabShow, setDIYTabShow] = useState(false);//DIYtab组件的出现与消失状态

  const [humidityLight, setHumidityLight] = useState(false); // 迫使红灯闪烁另加的一个状态
  const [isHumidity, setHumidity] = useState(false); // 湿度状态
  const [isWeight, setWeight] = useState(false); // 重量状态
  const [isTemperature, setTemperature] = useState(false); // 温度状态
  const [isShowUI, setShowUI] = useState(false); // UI按钮是否打开

  const [popIndex, setPopIndex] = useState(1); // 记录被点击的pop索引号

  const [isCooldesktopShow, setCooldesktopShow] = useState(false); // 干燥盒桌面页面的显示与消失


  const [gridConfig,setGridConfig] = useState({
        cellSize: 0.1,
        cellThickness: 1,
        cellColor: '#9CA5B4',
        sectionSize: 1,
        sectionThickness: 1,
        sectionColor: '#BCC4D4',
        fadeDistance: 12,
        fadeStrength: 1,
        followCamera: false,
        infiniteGrid: true
  });

  const fovsm = useMediaQuery(theme.breakpoints.down('sm'));
  const fovmd = useMediaQuery(theme.breakpoints.up('sm'));
  let USBOrbitX = '';
  let USBOrbitY = '';
  let USBOrbitZ = '';
  let USBCameraX = '';
  let USBCameraZ = '';

  let LetOutOrbitX,LetOutOrbitY,LetOutOrbitZ,LetOutCameraX, LetOutCameraY,LetOutCameraZ;
  let PowerOrbitX,PowerOrbitY,PowerOrbitZ,PowerCameraX,PowerCameraZ;
  let DIYOrbitX,DIYOrbitY,DIYOrbitZ,DIYCameraX,DIYCameraZ;
  let SizeX1,SizeY1,SizeZ1,SizeX2,SizeY2,SizeZ2,SizeX3,SizeY3,SizeZ3,SizeX4,SizeY4,SizeZ4;

  if(fovsm){
    USBOrbitX = -0.7;
    USBOrbitY = -0.9;
    USBOrbitZ = 0.5;
    USBCameraX = 2;
    USBCameraZ = -3;

    LetOutOrbitX = 0;
    LetOutOrbitY = -0.5;
    LetOutOrbitZ = 0;
    LetOutCameraX = 6;
    LetOutCameraY = 4;
    LetOutCameraZ = 0;

    PowerOrbitX = 0;
    PowerOrbitY = -0.5;
    PowerOrbitZ = 0;
    PowerCameraX = 3;
    PowerCameraZ = 0;

    DIYOrbitX = 0;
    DIYOrbitY = -0.7;
    DIYOrbitZ = 0;
    DIYCameraX = -7;
    DIYCameraZ = -5;


    SizeX1 = 2;
    SizeY1 = -1;
    SizeZ1 = 0 ;

    SizeX2 = -0.5;
    SizeY2 = 0.3;
    SizeZ2 = -1 ;

    SizeX3 = -2;
    SizeY3 = -2.5;
    SizeZ3 = -1 ;

    SizeX4 = 1;
    SizeY4 = 1.75;
    SizeZ4 = 0 ;
  }
  if(fovmd){
    USBOrbitX = 0;
    USBOrbitY = 0;
    USBOrbitZ = -1.2;
    USBCameraX = 2;
    USBCameraZ = -3;

    LetOutOrbitX = 0;
    LetOutOrbitY = 0;
    LetOutOrbitZ = -0.5;
    LetOutCameraX = 3.5;
    LetOutCameraY = 2.5;
    LetOutCameraZ =-0.4;

    PowerOrbitX = 0;
    PowerOrbitY = -0.5;
    PowerOrbitZ = 0.5;
    PowerCameraX = 3;
    PowerCameraZ = 0.5;

    DIYOrbitX = 0;
    DIYOrbitY = 0;
    DIYOrbitZ = 0.7;
    DIYCameraX = -5;
    DIYCameraZ = -3;

    SizeX1 = 1.4;
    SizeY1 = -2.5;
    SizeZ1 = 0 ;

    SizeX2 = 1.2;
    SizeY2 = 0.25;
    SizeZ2 = -3.2 ;

    SizeX3 = -0.1;
    SizeY3 = -2.5;
    SizeZ3 = -3.2 ;

    SizeX4 = 1;
    SizeY4 = 0.45;
    SizeZ4 = 0 ;
  }



  const cameraRef = useRef();
  const OrbitRef = useRef();
  const BoxRef=useRef();
  const TypeCB_Ref= useRef();//接口
  const TypeCA_Ref= useRef();//接口盖子
  const Push_Ref= useRef();//open按钮
  const LetR_Ref= useRef();//出料口右
  const LetL_Ref= useRef();//出料口左
  const LetOutLineL_Ref = useRef(); //左出料口管线
  const LetOutLineR_Ref = useRef(); //左出料口管线

  const Filament_Ref= useRef();//耗材
  const DryingDoorR_Ref= useRef();//干燥仓门右
  const DryingDoorL_Ref= useRef();//干燥仓门左
  const Door_Ref= useRef();//仓门
  const Tray_Ref=useRef();//耗材支撑
  const ScrewB_Ref= useRef();//后背螺丝
  const Roller_Ref= useRef();//称重滚轴
  const GasketF_Ref= useRef();//后密封圈
  const GasketB_Ref= useRef();//前密封圈
  const CapD_Ref= useRef();//后硅胶垫片
  const CapB_Ref= useRef();//底部硅胶垫片
  const BoxMain_Ref= useRef();//主体
  const DisplayUI_Ref= useRef();//显示屏UI
  const DisplayPanel_Ref= useRef();//显示屏
  const DisplayLight_Ref= useRef();//显示屏指示灯
  const DisplayButtonUP_Ref= useRef();//上按钮
  const DisplayButtonSet_Ref= useRef();//设置按钮
  const DisplayButtonDown_Ref= useRef();//下按钮
  const PIS_Ref=useRef();//贴纸
  const DoorSeal_Ref=useRef();//仓门垫片
  const DoorDolt_Ref= useRef();//仓门螺栓
  const GasketL_Ref= useRef();//仓门螺栓

  const Desiccant1_Ref=useRef();
  const Desiccant2_Ref=useRef();
  const Desiccant3_Ref=useRef();
  const Desiccant4_Ref=useRef();  // 提供上下文值（直接将状态对象作为 value）

  const SizeUI1Ref=useRef();
  const SizeUI2Ref=useRef();
  const SizeUI3Ref=useRef();
  const SizeUI4Ref=useRef();

  const GridRef = useRef();//网格

  const USBLineRef = useRef();//USB线
  const USBTab = useRef();//USB tab

  const contextValues = {
    isLoaded,
    isLoader,
    cameraPosition,
    isAutoRotate,
    isBtnOpen,
    visibleSizeUi,
    isBack,
    isSpecification,
    isSeal,
    isSpace,
    isTabOpacity,
    showDesiccant,
    isPushMouseEnter,
    isLetMouseEnter,
    isDisplayUIBright,
    isTypeC_Cap,
    gridConfig,
    isBatteryShow,
    humidityLight,
    isUITabShow,
    isHumidity,
    isWeight,
    isTemperature,
    isShowUI,
    isUSBTabShow,
    isPowerTabShow,
    isLetTabShow,
    isDIYTabShow,
    popIndex,
    isCooldesktopShow,

    setLoaded,
    setLoader,
    setCameraPosition,
    setAutoRotate,
    setBtnOpen,
    setVisibleSizeUi,
    setBack,
    setSpecification,
    setSeal,
    setSpace,
    setTabOpacity,
    setShowDesiccant,
    setPushMouseEnter,
    setLetMouseEnter,
    setDisplayUIBright,
    setGridConfig,
    setTypeC_Cap,
    setBatteryShow,
    setUITabShow,
    setHumidityLight,
    setHumidity,
    setWeight,
    setTemperature,
    setShowUI,
    setUSBTabShow,
    setPowerTabShow,
    setLetTabShow,
    setDIYTabShow,
    setPopIndex,
    setCooldesktopShow,

    cameraRef,
    OrbitRef,
    BoxRef,
    TypeCB_Ref,//接口
    TypeCA_Ref,//接口盖子
    Push_Ref,//open按钮
    LetR_Ref,//出料口右
    LetL_Ref,//出料口左
    LetOutLineL_Ref,//出料口左管线
    LetOutLineR_Ref,//出料口右管线
    Filament_Ref,//耗材
    DryingDoorR_Ref,//干燥仓门右
    DryingDoorL_Ref,//干燥仓门左
    Door_Ref,//仓门
    Tray_Ref,//耗材支撑
    ScrewB_Ref,//后背螺丝
    Roller_Ref,//称重滚轴
    GasketF_Ref,//后密封圈
    GasketB_Ref,//前密封圈
    CapD_Ref,//后硅胶垫片
    CapB_Ref,//底部硅胶垫片
    BoxMain_Ref,//主体
    DisplayUI_Ref,//显示屏UI
    DisplayPanel_Ref,//显示屏
    DisplayLight_Ref,//显示屏指示灯
    DisplayButtonUP_Ref,//上按钮
    DisplayButtonSet_Ref,//设置按钮
    DisplayButtonDown_Ref,//下按钮
    PIS_Ref,//贴纸
    DoorSeal_Ref,//仓门垫片
    DoorDolt_Ref,//仓门螺栓
    GasketL_Ref,

    /* 干燥剂 */
    Desiccant1_Ref,
    Desiccant2_Ref,
    Desiccant3_Ref,
    Desiccant4_Ref,

    /* 尺寸 */
    SizeUI1Ref,
    SizeUI2Ref,
    SizeUI3Ref,
    SizeUI4Ref,

    GridRef,//网格
    USBLineRef,
    USBTab,

    fovsm,
    fovmd,

    

    USBOrbitX,
    USBOrbitY,
    USBOrbitZ,
    USBCameraX,
    USBCameraZ,

    LetOutOrbitX,LetOutOrbitY,LetOutOrbitZ,LetOutCameraX,LetOutCameraY,LetOutCameraZ,
    PowerOrbitX,PowerOrbitY,PowerOrbitZ,PowerCameraX,PowerCameraZ,
    DIYOrbitX,DIYOrbitY,DIYOrbitZ,DIYCameraX,DIYCameraZ,

    SizeX1,SizeY1,SizeZ1,SizeX2,SizeY2,SizeZ2,SizeX3,SizeY3,SizeZ3,SizeX4,SizeY4,SizeZ4,

    tabui:{
      OrbitX1:fovsm ? -0.14 :-0.5,
      OrbitY1:fovsm ? -0.1 :0.1,
      OrbitZ1:fovsm ? 0.2 :0.2,
      CameraX1:fovsm ? 1 :0.6,
      CameraZ1:fovsm ? 2.8 :2,

      OrbitX2:fovsm ? -0.2 :-1.3,
      OrbitY2:fovsm ? -0.5 :-0.6,
      OrbitZ2:fovsm ? -0.15 :-0.65,
      CameraX2:fovsm ? 4 : 2.2,
      CameraZ2:fovsm ? 4 : 2.8,
      fov:fovmd ? 'vertical' :''
    },
    tab:{
      OrbitX1:fovsm ? 0.1 :0.5,
      OrbitY1:fovsm ? -0.9 :-0.25,
      OrbitZ1:fovsm ? 0.1 :0.5,
      CameraX1:fovsm ? 7 :4,
      CameraZ1:fovsm ? -7 :-4,

      OrbitX2:fovsm ? 0 :-0.5,
      OrbitY2:fovsm ? -0.7 :0,
      OrbitZ2:fovsm ? 0 :0.5,
      CameraX2:fovsm ? 8 : 5,
      CameraZ2:fovsm ? 8 : 5,

      OrbitX3:fovsm ? 0 :0,
      OrbitY3:fovsm ? -1 :-0.3,
      OrbitZ3:fovsm ? 0 :0.4,
      CameraX3:fovsm ? 6 : 4,
      CameraZ3:fovsm ? 0 : 0.4,
      fov:fovmd ? 'vertical' :''
    },

    //标签点击时用到
    modelLabel:{
      OrbitX:fovsm ? 0 :0.5,
      OrbitY:fovsm ? -0.7 :-0.25,
      OrbitZ:fovsm ? 0.1 :0.5,
      CameraX:fovsm ? 6 :4,
      CameraZ:fovsm ? -6 : -4,

      OrbitX2:fovsm ? -0.14 :-0.5,
      OrbitY2:fovsm ? -0.1 :0.1,
      OrbitZ2:fovsm ? 0.2 :0.2,
      CameraX2:fovsm ? 1 : 0.6,
      CameraZ2:fovsm ? 2.8 : 2,
    }
  };


  return (
    <MyContext.Provider value={contextValues}>
      {children}
    </MyContext.Provider>
  );
};

// 自定义 hook，用于访问 Context 中的状态
export const useMyContext = () => useContext(MyContext);

