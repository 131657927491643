import * as React from 'react';
import {forwardRef, useEffect, useRef, useCallback, useState} from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';
import { useMediaQuery } from '@mui/material'
import theme from './theme'
import HumiditySVG from './img/Humidity.svg'
import WeightSVG from './img/Weight.svg'
import TemperatureSVG from './img/Temperature.svg'
import { Favorite, Person, Place } from '@mui/icons-material'; 

const TabUI = ({ ...props })=> {
  const context = useMyContext();
  const [value, setValue] = React.useState('1');


  const handleChange = (event, newValue) => {

      if(context.isHumidity || context.isWeight || context.isTemperature){
          setValue(newValue);
          switch (newValue) {
              case '1':humidityClick();break;
              case '2':weightClick();break;
              case '3':temperatureClick();break;
          }
      }

  };

  // Back按钮点击事件
  const SmartClick = ()=>{

    context.setAutoRotate(true);
      context.setHumidity(false);
      context.setWeight(false);
      context.setTemperature(false);
      context.setShowUI(false);
      context.setHumidityLight(false);

      context.setGridConfig({...context.gridConfig,fadeDistance:12});

      context.Filament_Ref.current.children[0].material.transparent = false;
      context.Filament_Ref.current.children[1].material.transparent = false;
      context.Filament_Ref.current.children[2].material.transparent = false;
      context.Filament_Ref.current.children[3].material.transparent = false;

      gsap.to(context.OrbitRef.current.target, { duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'})
      gsap.to(context.cameraRef.current.position, {  onComplete:()=>{context.setAutoRotate(true);},duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut'})
      gsap.to('.UITab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setUITabShow(false)}})
  }

  // 湿度选项卡方法
  const humidityClick = () => {

      context.setHumidity(true);
      context.setWeight(false);
      context.setTemperature(false);
      const tl =gsap.timeline({onComplete:function(){context.setAutoRotate(false);}});
      tl
          .to(context.cameraRef.current.position, { duration:1,x:context.tabui.CameraX1, y:-0.6, z:context.tabui.CameraZ1 ,ease: 'power2.inOut'})
          .to(context.OrbitRef.current.target, { duration:1,x:context.tabui.OrbitX1, y:context.tabui.OrbitY1, z:context.tabui.OrbitZ1 ,ease: 'power2.inOut'},"<")
      context.setGridConfig({...context.gridConfig,fadeDistance:5});

  }
  // 重量选项卡方法
    const weightClick = () => {

        context.setHumidity(false);
        context.setWeight(true);
        context.setTemperature(false);
        const tl =gsap.timeline({onComplete:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.tabui.CameraX2, y:1.2, z:context.tabui.CameraZ2 ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.tabui.OrbitX2, y:context.tabui.OrbitY2, z:context.tabui.OrbitZ2 ,ease: 'power2.inOut'},"<")
        context.setGridConfig({...context.gridConfig,fadeDistance:5});
  }
  // 温度选项卡方法
    const temperatureClick = () => {
        context.setHumidity(false);
        context.setWeight(false);
        context.setTemperature(true);
        const tl =gsap.timeline({onComplete:function(){context.setAutoRotate(false);}});
        tl
            .to(context.cameraRef.current.position, { duration:1,x:context.tabui.CameraX1, y:-0.6, z:context.tabui.CameraZ1 ,ease: 'power2.inOut'})
            .to(context.OrbitRef.current.target, { duration:1,x:context.tabui.OrbitX1, y:context.tabui.OrbitY1, z:context.tabui.OrbitZ1 ,ease: 'power2.inOut'},"<")
        context.setGridConfig({...context.gridConfig,fadeDistance:5});
  }

  useGSAP(()=>{
    if(context.isUITabShow){
      gsap.to('.UITab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
      gsap.to('.UITab_card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
    }
  },[context.isUITabShow])

  return (
    <Box 
    className='UITab'
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-start',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
      <Button 
        sx={{
          marginBottom:'3vh',
        }}
        variant='outlined'
        onClick={
          ()=>{
              if(context.isHumidity || context.isWeight || context.isTemperature){
                  SmartClick();
              }else{
                  return false;
              }
          }
        }
        startIcon={<ArrowBackIcon />}
      >
        <Typography color="text.primary" variant="button">
          BACK
        </Typography>
      </Button>

      <Box
      className='UITab_card'
          sx={{
            width:{
              xs:'100%',
              sm:'40vw',
            },
            boxSizing:'border-box',
            display:'flex',
            flexDirection:{
              xs:'column',
              sm:'row',
            },
            alignItems: 'center',
            background: 'rgba(251, 251, 253, 0.1)',
            boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
            webkitBackdropFilter: ' blur(10px)',
            backdropFilter: 'blur(10px)',
            padding:{
              xs:'1rem',
              sm:'2rem',
            },
            borderRadius:{
              xs:'10px',
              sm:'20px',
            },
          }}
        >
          <TabContext
           value={value} >
            
            <Box
              sx={{
              width:{
                xs:'100%',
                sm:'25%',
              },
            }}
            >
              <Tabs 
                  onChange={handleChange} //点击事件
                  orientation={context.tabui.fov}
                  value={value}
                  sx={{
                    display: 'flex',
                  }}
              >
                    <Tab
                        sx={{ flex: 1 }}
                        label={<Typography variant="button">Humidity</Typography>}
                        value="1"
                        icon={<img src={HumiditySVG} alt="Humidity" />}
                    />

                    <Tab
                        sx={{ flex: 1 }}
                        label={<Typography variant="button">Weight</Typography>}
                        value="2"
                        icon={<img src={WeightSVG} alt="Weight" />}
                    />

                    <Tab
                        sx={{ flex: 1 }}
                        label={<Typography variant="button">Temperature</Typography>}
                        value="3"
                        icon={<img src={TemperatureSVG} alt="Temperature" />}
                    />
              </Tabs>
            </Box>
            
            <Box
              sx={{
                marginTop:{
                  xs:'1rem',
                  sm:'0',
                },
                marginLeft:{
                  xs:'0',
                  sm:'2rem',
                },
                width:{
                  xs:'100%',
                  sm:'75%',
                },
              }}
            >
              <TabPanel value="1">
                  <Typography color="text.primary" variant="body2">When the desiccant becomes saturated the CoCoon will display a warning sign and alert you to replace your desiccant.</Typography>
              </TabPanel>

              <TabPanel value="2">
                  <Typography color="text.primary" variant="body2">The CoCoon is equipped with a weight scale to display the current remaining weight of the filament. With this new feature you can better plan and asses your future prints.<br /><br />
Due to filament manufacturers using different filament spools you can set the “empty spool weight” on the display and as such adjust get an accurate reading of the remaining filament weight.</Typography>
              </TabPanel>

              <TabPanel value="3">
                  <Typography color="text.primary"  variant="body2">Real-time monitoring of the chamber temperature provides a comprehensive understanding of the filament storage environment.</Typography>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>

      </Box>
      
    </Box>
  );
}

export default TabUI