import * as React from 'react';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useMyContext } from './stateContext';


const TabAOTLET = ({ ...props })=> {
  const context = useMyContext();
  let flag = true;

  const backToHome = () => {

          gsap.to([context.LetOutLineL_Ref.current.children[0].material,
                  context.LetOutLineL_Ref.current.children[1].material,
                  context.LetOutLineL_Ref.current.children[2].material
              ],
              {
                  duration:1,
                  opacity: 0,
                  delay:1,
                  onComplete:()=>{
                      context.setAutoRotate(true);
                      gsap.to(context.OrbitRef.current.target, { duration:1,x:0, y:0, z:0 ,ease: 'power2.inOut'})
                      gsap.to(context.cameraRef.current.position, {duration:1,x:context.cameraPosition.x, y:context.cameraPosition.y, z:context.cameraPosition.z ,ease: 'power2.inOut'});
                      gsap.to('.LetTab',{duration:1,opacity:0 ,ease: 'power2.inOut',onComplete:function(){context.setLetTabShow(false)}})
                  }
              })
          gsap.to(context.LetOutLineL_Ref.current.position,{
              duration:1,
              z:2.5,
              ease: 'power2.inOut',
              delay:0,
          })
          gsap.to(context.LetOutLineR_Ref.current.position,{
              duration:1,
              y:2.5,
              ease: 'power2.inOut',
              delay: 0,
          })
          gsap.to(context.LetOutLineL_Ref.current.rotation,{
              duration:1,
              y:0,
              ease: 'power2.inOut',
              delay:0,
          })
          gsap.to(context.LetOutLineR_Ref.current.rotation,{
              duration:1,
              y:0,
              ease: 'power2.inOut',
              delay: 0
          })

  }
    useGSAP(()=>{
      if(context.isLetTabShow){
        gsap.to('.LetTab',{duration:1,opacity:1 ,ease: 'power2.inOut',})
        gsap.to('.LetTab_card',{duration:1,webkitBackdropFilter: ' blur(10px)',backdropFilter: 'blur(10px)' ,ease: 'power2.inOut',})
      }
    },[context.isLetTabShow])

  return (
    <Box 
    className='LetTab'
    sx={{
      position:'fixed',
      zIndex:'999',
      display:'flex',
      height:'100vh',
      boxSizing:'border-box',
      padding:'4vw',
      opacity:0,
      bottom: 0,
      justifyContent:{
        xs:'center',
        sm:'flex-end',
      },
      alignItems:{
        xs:'flex-end',
        sm:'center',
      },
      width:'100%',
    }}
    >
      <Box 
        sx={{
          display:'flex',
          transform:{
           xs:'perspective(1500px) rotateY(0deg) rotateX(0deg)',
           sm:'perspective(1500px) rotateY(-20deg)',
          },
          flexDirection:'column',
          alignItems: 'flex-start',
        }}
      >
        <Button
          sx={{
            marginBottom:'3vh',
          }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick = {
              ()=>{
                  if(flag){
                      backToHome();
                  }
                  flag = false;
              }
            }
        >
          <Typography color="text.primary" variant="button">
            BACK
          </Typography>
        </Button>

        <Box
        className='LetTab_card'
            sx={{
              width:{
                xs:'100%',
                sm:'40vw',
              },
              boxSizing:'border-box',
              display:'flex',
              flexDirection:'column',
              alignItems: 'center',
              background: 'rgba(251, 251, 253, 0.1)',
              boxShadow: 'inset 0px 0.5px 2px rgba(225, 225, 225, 0.4)',
              webkitBackdropFilter: 'blur(0px)',
              backdropFilter: 'blur(0px)',
              padding:{
                xs:'1rem',
                sm:'2rem',
              },
              borderRadius:{
                xs:'10px',
                sm:'20px',
              },
            }}
        >
          <Typography color="text.primary"  gutterBottom variant="h4">
            Double filament paths, give you a creative freedom to use the filament
          </Typography>
          
          <Typography color="text.primary"  variant="body2">
            The double filament paths design and flexible filament system give you a variety of DIY placement options.
          </Typography>

        </Box>

      </Box>
      
    </Box>
  );
}

export default TabAOTLET