import { useGLTF } from "@react-three/drei";
import { useMyContext } from '../../stateContext';
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";
import { useEffect } from "react";


function LetOut(props) {
  const { nodes, materials } = useGLTF("/LetOut.glb");

  const context = useMyContext();

    useGSAP(()=>{
        /* 出料口按钮的移入移出 */
        gsap.to([context.LetOutLineL_Ref.current.children[0].material,
            context.LetOutLineL_Ref.current.children[1].material,
            context.LetOutLineL_Ref.current.children[2].material
        ],
            {
            duration:1,
            opacity:1,
            delay:1
        })
        gsap.to(context.LetOutLineL_Ref.current.position,{
            duration:1,
            z:1.8,
            ease: 'power2.inOut',
            delay: 2,
        })
        gsap.to(context.LetOutLineR_Ref.current.position,{
            duration:1,
            y:1.8,
            ease: 'power2.inOut',
            delay:2,
        })
        gsap.to(context.LetOutLineL_Ref.current.rotation,{
            duration:1,
            y:Math.PI,
            ease: 'power2.inOut',
            delay:2,
        })
        gsap.to(context.LetOutLineR_Ref.current.rotation,{
            duration:1,
            y:Math.PI,
            ease: 'power2.inOut',
            delay:2,
        })



    },[])


materials["semi-transparent"].transparent = true;
materials.Metal_Gold.transparent = true;
materials["Glass-Black"].transparent = true
materials["semi-transparent"].opacity = 0;
materials.Metal_Gold.opacity = 0;
materials["Glass-Black"].opacity = 0;

  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.069, 1.822, 2.5]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={4.02}
        ref={context.LetOutLineL_Ref}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose001.geometry}
          material={materials["semi-transparent"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose001_1.geometry}
          material={materials.Metal_Gold}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose001_2.geometry}
          material={materials["Glass-Black"]}
        />
      </group>

      <group position={[-0.069, 2.5, -1.707]} scale={4.02} ref={context.LetOutLineR_Ref}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose.geometry}
          material={materials["semi-transparent"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose_1.geometry}
          material={materials.Metal_Gold}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.hose_2.geometry}
          material={materials["Glass-Black"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/LetOut.glb");
export default LetOut